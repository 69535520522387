import axios from "axios";

import router from "@/router";

export default {
    state: {
        token: localStorage.getItem('user-token') || '',
    },
    mutations: {
        AUTH_SUCCESS(state, token) {
            state.token = token;
        },
        AUTH_ERROR(state) {
            state.token = '';
        },
        RESET_LOGIN(state) {
            localStorage.removeItem('user-token');
            state.token = '';
            router.push({ name: 'login' });
        }
    },
    actions: {
        async login({ commit }, user) {
            try {
                user = {'user': btoa(JSON.stringify(user)) }
                const response = await axios.post(process.env.VUE_APP_APP_APIURL + '/api/login', user);
                if(response.data.status == 'success') {
                    localStorage.setItem('user-token', response.data.data.token);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.data.token;
                    commit('AUTH_SUCCESS', response.data.data.token);
                    router.push({ name: 'home' });
                } else if(response.data.status == 'error') {
                    throw new Error (response.data.data.msg);
                } else {
                    throw new Error ('User not found!');
                }
            } catch (err) {
                localStorage.removeItem('user-token');
                commit('AUTH_ERROR');
                throw err;
            }
        }
    },
};
