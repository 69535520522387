import {format} from 'date-fns';


export default {
    methods: {
        formatDate(value, formatString = 'dd.MM.yyyy') {
            return format(new Date(value), formatString)
        }
    }
}
