<template>
  <div class="flex flex-row">
    <div class="flex flex-row relative w-full">
      <input type="text" class="flex-1 bg-wuh-secondary rounded-2xl mr-3 px-4" v-model="debouncedSearchtext" placeholder="Suchen">
      <button @click="debouncedSearchtext = ''" class="absolute right-0 top-0 mt-[-2px] mr-3 " type="button">
        <img src="@/assets/icons/close.svg" class="w-10 h-10"/>
      </button>
    </div>

    <a href="#" class="">
      <img src="@/assets/icons/filter.svg" class="w-10" @click.prevent="showsearchfields = !showsearchfields"/>
    </a>
  </div>
  <div class="flex flex-row w-full" v-if="showsearchfields">
    <div class="flex w-full mt-1 monthyearfilter">
      <div class="w-2/6 mr-1" v-if="showyearmonthfilter">
         <v-select class="w-full" v-model="filter.monthfilter" placeholder="Monat"
                  :options="monthlist"
                  :reduce="item => item.value"
        />
      </div>
      <div class="w-2/6 mr-1" v-if="showyearmonthfilter">
        <v-select class="w-full" v-model="filter.yearfilter" placeholder="Jahr"
                  :options="yearlist"
                  :reduce="item => item.value"
        />
      </div>

      <div class="w-2/6">
        <v-select class="w-full"
                  v-if="showstatusfilter"
                  v-model="filter.status"
                  placeholder="Status"
                  :options="status"
                  :reduce="item => item.value"
        />
      </div>
    </div>

  </div>

  <div class="flex flex-row">
    <div class=" flex-row mt-3">
      <div class=" flex-row">
        <ul class="tagfilters flex w-full flex-wrap">
          <template v-for="(item, index) in filteroptions.tags" v-bind:key="item">
            <template v-if="index < 5">
              <li >
                <a href="#" @click.prevent="addfiltertag(item, index)" class="tagbubble">#{{ item }}</a>
              </li>
            </template>
            <template v-if="index > 5 && showalltags">
              <li >
                <a href="#" @click.prevent="addfiltertag(item, index)" class="tagbubble">#{{ item }}</a>
              </li>
            </template>
          </template>
          <li class="" v-if="filteroptions.tags.length > 5">
            <a href="#" class="text-wuh-green" v-if="!showalltags" @click.prevent="showalltags = !showalltags"><i class="fas fa-angle-double-right text-[14px] mt-2"></i></a>
            <a href="#" class="text-wuh-green" v-if="showalltags" @click.prevent="showalltags = !showalltags"><i class="fas fa-angle-double-left text-[14px] mt-2"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    filteroptions: {
      type: Array,
      default: function() { return []; }
    },
    showstatusfilter: {
      type: Boolean,
      default: false
    },
    showyearmonthfilter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filter: {
        searchtext: '',
        searchtags: [],
        yearfilter: '',
        monthfilter: '',
        status: ''
      },
      status: [
        {label: 'alle', value: 'all'},
        {label: 'Unveröffentlicht', value: '3'},
        {label: 'Veröffentlicht', value: '1'},
        {label: 'zur Prüfung freigegeben', value: '2'},
      ],
      showsearchfields: false,
      monthlist: [
            {label: 'Januar', value: '01'},
            {label: 'Februar', value: '02'},
            {label: 'März', value: '03'},
            {label: 'April', value: '04'},
            {label: 'Mai', value: '05'},
            {label: 'Juni', value: '06'},
            {label: 'Juli', value: '07'},
            {label: 'August', value: '08'},
            {label: 'September', value: '09'},
            {label: 'Oktober', value: '10'},
            {label: 'November', value: '11'},
            {label: 'Dezember', value: '12'}
      ],
      currentYear: new Date().getFullYear(),
      showalltags: false
    }
  },
  computed: {
    yearlist() {
      let objArray = this.filteroptions.years.map(jahr => ({label: jahr, value: jahr}))
      return objArray
      // return [
      //     {label: this.currentYear - 1, value: this.currentYear - 1},
      //     {label: this.currentYear, value: this.currentYear}];
    },
    debouncedSearchtext: {
      get() {
        return this.filter.searchtext
      },
      set(value) {
        clearTimeout(this.timer)
        // const hashtagDetected = /#\w+/g.test(value); // checking for existence of hashtag
        let timout = 500
        this.timer = setTimeout(() => {
          // this.loadimages()
          console.locallog('debouncedSearchText', value) //.match(/#\w+/g))
          // // if(value.indexOf('#') > 0 && value.match(/#\w+/g) > 5) {
          //   this.filter.searchtags = [...this.filter.searchtags, ...(value.match(/#\w+/g) || [])];
          //   let clearedfield = value.replace(/#\w+/g, '').trim();
          //   this.filter.searchtext = clearedfield
          // // } else {
          this.filter.searchtext = value

          this.$emit('updateFilter', this.filter);
          // // }
        }, timout)
      }
    }
  },
  methods: {

    addfiltertag(item, index) {
      console.locallog(index)
      this.filter.searchtext = this.filter.searchtext + ' #' + item
    },
    removetag(index) {
      // let r = this.filter.searchtags.splice(index, 1);
      this.filter.searchtags.splice(index, 1);
      this.filter.searchtags = [...this.filter.searchtags]
      console.locallog('Splice element ', index, this.filter.searchtags)
      // this.filter.searchtags = r
    },
  },
  watch: {
    'filter.searchtext'(value, oldval) {
      // this.inputValue = value;
      // this.debounceInput = value;
      // const hashtagDetected = /#\w+/g.test(value); // checking for existence of hashtag

      console.locallog('filter.searchtext change', value, oldval)
      this.$emit('updateFilter', this.filter);
      // if (!hashtagDetected) { // if no hashtag is found
      //   this.loadimages()
      // }
    },
    'filter.monthfilter'(value, oldval) {
      console.locallog('filter.monthfilter change', value, oldval)
      this.$emit('updateFilter', this.filter);
    },
    'filter.yearfilter'(value, oldval) {
      console.locallog('filter.yearfilter change', value, oldval)
      this.$emit('updateFilter', this.filter);
    },
    'filter.searchtags'(value, oldval) {
      console.locallog('filter.searchtags change', value, oldval)
    },
    'filter.status'(value, oldval) {
      console.locallog('filter.status change', value, oldval)
      this.$emit('updateFilter', this.filter);
    }
  }

}
</script>

<style lang="scss" scoped>
.tagfilters li a {
  font-size: 0.6rem;
}
.tagbubble {
  @apply py-0.5 px-1.5 bg-wuh-lightgreen rounded-2xl inline-flex items-center text-xxs;
}

</style>
