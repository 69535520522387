export default {
    namespaced: true,
    state: {
        loading: 0
    },
    mutations: {
        SET_LOADING(state, status) {
            if(status) {
                state.loading++
            } else {
                state.loading--
                if(state.loading < 0) {
                    state.loading = 0
                }
            }
        },
    },
    actions: {
        setloading({ commit }, $status) {
            if($status == true) {
                commit('SET_LOADING', true)
            } else {
                commit('SET_LOADING', false)
            }
        }
    },
    modules: {
    }
};
