import axios from "axios";


export default {

    async sendPost(url, data) {
        console.locallog('sendPost Call', url, data)
        const response = await axios.post(url, data)
        return response.data;
    },
    async removeComment( data) {
        const url = process.env.VUE_APP_APP_APIURL + '/api/collection/removecomment'
        console.locallog('ApiCall 2', url, data)
        return axios.post(url, data)
    },
    async getComments( data) {
        const url = process.env.VUE_APP_APP_APIURL + '/api/collection/getcomments'
        console.locallog('getComments', url, data)
        return axios.post(url, data)
    },
    async saveComment( data) {
        const url = process.env.VUE_APP_APP_APIURL + '/api/collection/savecomment'
        console.locallog('saveComment', url, data)
        return axios.post(url, data)
    }


};
