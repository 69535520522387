<template>
  <a href="#" class="mr-0 ml-1" @click.prevent="showModal = true" v-if="$store.state.currentuser.user.role == 'admin' || $store.state.currentuser.user.role == 'redakteur'">
    <img src="@/assets/icons/add-image.svg" class="w-10" />
  </a>

  <ModalView :show="showModal" @close="showModal = false" >
<!--    <h1>Bildupload in {{currentpath.length}}</h1>-->
<!--    <h1>Bildupload in {{ currentpath[currentpath.length - 1].path}}</h1>-->
<!--    <h1>Bildupload in {{ currentpath }}</h1>-->

      <div v-if="iswriteable" class="w-[44rem] max-w-[44rem]">

        <div class="targetselet">
          <div class="flex flex-row mt-5 mb-5">
            <div class="flex flex-col w-full">
              <label for="name" class="metainputlabel">Ordner auswählen</label>
<!--              ({{shortendir(currentpathstring)}})-->
              <v-select class="metainputfield" v-model="targetfolder"
                        v-if="decotargetfolders && decotargetfolders.length > 0"
                        :options="decotargetfolders"
                        label="pathtitle"
                        :reduce="item => item.path"
              >
<!--                <template v-slot:option="option">-->
<!--                  {{ option && option.title ? decodeURIComponent(option.title) : '' }}-->
<!--                </template>-->
              </v-select>
            </div>
          </div>
        </div>


        <div id="drop-zone"
             @dragover.prevent="dragOver"
             @drop.prevent="drop"
             class=" p-[20px]"
             :class="{ hover: isDrag }"
              v-if="targetfolder != ''">

          <input type="file"
                 id="fileElem"
                 multiple
                 accept="image/*"
                 @change="handleFiles($event.target.files)"
                 class="hidden">

          <p>Datei(en) ziehen und ablegen</p>
          <label class="btn bg-wuh-btn-green" for="fileElem" >Oder hier klicken und auswählen</label>
        </div>

        <div class="uploadcontainer w-full flex">
          <div class="w-1/3" v-for="(item, index) in files" :key="index">
            <div class="p-[1rem] justify-center items-center flex">
              <img :src="item.preview" class="max-h-24 justify-center items-center"/>
            </div>
            <div v-for="(progress, index) in uploadProgress" :key="index" class="w-full">
              <div class="relative pt-1 w-full">
                <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-wuh-lightgreen h-[1.5rem]">
                  <div id="progress" :style="{ width: progress + '%' }" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-wuh-green h-[1.5rem]"></div>
                </div>
                <div id="percentage" class="text-gray-700 absolute w-full text-center top-0 pt-1">
                  {{ progress }}
                </div>
              </div>
            </div>
          </div>



        </div>



<!--        <div v-for="(uploadedfile, index) in uploaded" :key="index">-->
<!--          File {{ index + 1 }}: {{ uploadedfile }}%-->
<!--        </div>-->
      </div>

  </ModalView>

</template>

<script>
import axios from "axios";
import {mapActions} from "vuex";
import ModalView from "@/components/ModalView.vue";
import emitter from "@/addons/event-bus";

export default {
  name: 'ImageUpload',
  components: {ModalView},
  props: {
    currentpath: {
      type: Array
    },

  },
  data() {
    return {
      files: [],
      showModal: false,
      isDrag: false,
      uploadPercentage: 0,
      iswriteable: true,
      uploadProgress:[],
      uploaded:[],
      targetfolder: '',
      targetfolders: []
    }
  },
  computed: {
    computedProp() {
      if(this.currentpath.length >= 1) {
        return this.currentpath
      }
      console.locallog('CurrentUser', this.$store.state.currentuser.user.defaultuploadfolder)
      return [{path: this.$store.state.currentuser.user.defaultuploadfolder}]
      // return [{path:'/'}]
    },
    decotargetfolders() {

      console.locallog('Targetfolders', this.targetfolders)
      return this.targetfolders
          .filter(option => option.path !== '/')
          .map(option => {
            return { ...option, title: decodeURIComponent(option.title), pathtitle: decodeURIComponent(option.path) };
          });
      // return this.targetfolders.map(option => {
      //   return { ...option, title: decodeURIComponent(option.title), pathtitle: decodeURIComponent(option.path) };
      // });
    },
    currentpathstring() {
      if(this.currentpath.length >= 1) {
        return this.currentpath[this.currentpath.length - 1].path;
      }

      // console.locallog('CurrentUser', this.$store.state.currentuser.user.defaultuploadfolder)
      return this.$store.state.currentuser.user.defaultuploadfolder
      // return ''
    }
  },
  created() {
    console.locallog('ImageUpload Created')
    this.fetchtargetfolders()
  },
  mounted() {
    console.locallog('Currentuser', this.$store.state.currentuser.user)
    console.locallog('ImageUpload Mounted')
  },
  methods: {
    ...mapActions([]),

    shortendir(path) {
      if(path != null) {
        const webdavIdx = path.indexOf('/webdav/');
        if (webdavIdx !== -1) {
          return '/' + path.substr(webdavIdx + 8);
        }
      }
      return path;
    },
    converttext(textin) {
      console.locallog('option', textin)
      return decodeURIComponent(textin)
    },
    dragOver(e) {
      this.isDrag = true;
      e.preventDefault();
    },
    drop(e) {
      this.isDrag = false;
      let files = e.dataTransfer.files;

      console.locallog('Files Drop', files)
      if (files.length > 0) {
        this.handleFiles(files)
      }
    },
    handleFiles(files) {
      for (let i = 0; i < files.length; i++) {
        console.locallog('Handle Files', files[i].name)
        if (/\.(jpe?g|png|gif)$/i.test(files[i].name)) {
          files[i].preview = URL.createObjectURL(files[i])

          this.files.push(files[i])
          this.uploadFile(files[i]);
        }
      }
    },
    async uploadFile(file) {
      let _this = this;
      console.locallog('File', file, this.currentpath);
      if(!this.files) {
        console.error('No file selected');
        return;
      }

      this.uploadProgress = {};

      // Convert FileList to Array
      const files = Array.from(this.files);
      const index = files.indexOf(file)

      // for (const [index, file] of files.entries()) {
      const formData = new FormData();
      formData.append('file', file);
      if(_this.currentpath.length >= 1) {
        const lastPath = _this.currentpath[_this.currentpath.length - 1].path;
        formData.append('path', lastPath);
        // formData.append('path', _this.currentpath[_this.currentpath.legnth-1].path)
      } else {
        console.locallog('Currentuser', _this.$store.state.currentuser.user)
        formData.append('path', _this.$store.state.currentuser.user.defaultuploadfolder)
        // formData.append('path', '/')
      }

      if(this.targetfolder != '') {
        formData.set('path', this.targetfolder)
      }


      // console.locallog('Formdata', formData, this.targetfolder, _this.currentpath[_this.currentpath.length - 1].path)

      try {
        await axios.post(process.env.VUE_APP_APP_APIURL + '/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          // Provide onUploadProgress callback function that updates the uploadProgress
          onUploadProgress: progressEvent => {
            this.uploadProgress[index] = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
            );
            // Check if upload progress is 100%
            if (this.uploadProgress[index] === 100) {
              // Remove uploaded file from the list
              if (index > -1) {
                this.uploadProgress[index] = 'Einen Moment, bitte'
                this.uploaded.push(this.files[index]);
                this.files = this.files.splice(index, 1);
              }
            }
          }
        }).then((data) => {
          this.uploadProgress[index] = 'Daten erfolgreich aufbereitet.'
          console.locallog('Response', data);
          if(data.data.fileid) {
            emitter.emit('filesuploaded', data.data.data.image)
            this.showModal = false
          }

          // alert('Upload finished!');
        }).catch(error => {
          console.locallog('error', error)
        })

        this.$store.commit('globalstore/SET_LOADING', false)

      } catch (error) {
        console.error('Error', error);
        this.$store.commit('globalstore/SET_LOADING', false)
      }


      // Here you can send the file with an AJAX request to your server for upload
    },

    async fetchtargetfolders() {
      console.locallog('fetchtargetfolders')

      let data = {}
      this.$store.commit('globalstore/SET_LOADING', true)

      try {
        await axios.post(process.env.VUE_APP_APP_APIURL + '/api/files/gettargetdirectories', data)
          .then((response) => {
            if(response.data.status == 'success') {
              // Ordner gelöscht.
              console.locallog('FolderFetchResult',response.data.data)
              this.targetfolders = response.data.data.folders

              // basepath
              if(this.currentpath.length >= 1) {
                const lastPath = this.currentpath[this.currentpath.length - 1].path;
                const foundItem = this.targetfolders.find((item) => {
                  let testpath = 'webdav' + item.path
                  console.locallog('Comp', lastPath, testpath)
                  return lastPath.endsWith(testpath)
                })
                console.locallog('LastPath', lastPath, foundItem)
                if( foundItem ) {
                  this.targetfolder = foundItem.path
                }
              }






            }
          })
          .catch(error => {
            console.locallog('error', error)
          });


        this.$store.commit('globalstore/SET_LOADING', false)

      } catch (error) {
        console.error('Error', error);
        this.$store.commit('globalstore/SET_LOADING', false)
      }
    }


  },
  watch: {
    showModal: function() {
      if(this.showModal == true) {
        this.fetchtargetfolders()
      }
    },
    currentpath: function() {
      console.locallog('Currentpath', this.currentpath)
      if(this.currentpath.length -1 == -1) {
        this.iswriteable = true
      } else {
        let currentpath = this.currentpath[this.currentpath.length -1]
        let pathpermission = currentpath.permissions.indexOf('CK')
        console.locallog('Pathpermissions', pathpermission > 0)
        this.iswriteable = pathpermission > 0

      }
    }
  }

}
</script>

<style lang="scss" scoped>

.hidden {
  display: none;
}

#drop-zone {
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 50px;
  text-align: center;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease-in-out;
}

#drop-zone.hover {
  background-color: #f0f0f0;
}

.button {
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}
</style>
