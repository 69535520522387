import { createRouter, createWebHistory } from 'vue-router'
// import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store';
// import HomeView from '@/views/HomeView.vue'
// import ImagesView from "@/views/ImagesView.vue";
// import StatisticView from "@/views/StatisticView.vue";
// import UseradminView from "@/views/UseradminView.vue";
// import ApprovalView from "@/views/ApprovalView.vue";
// import SharedView from "@/views/SharedView.vue";
// import FolderView from "@/views/FolderView.vue";
// import PassresetView from "@/views/PassresetView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      layout: 'Default',
      requiresAuth: true
    }
  },
  {
    path: '/home',
    name: 'dhome',
    component: () => import('../views/HomeView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      layout: 'login'
    }
  },
  {
    path: '/myimages',
    name: 'myimages',
    component: () => import('../views/ImagesView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/myfolder',
    name: 'myfolder',
    component: () => import('../views/FolderView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/images',
    name: 'images',
    component: () => import('../views/ImagesView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/myshares',
    name: 'myshares',
    component: () => import('../views/SharedView.vue'),
    meta: {
      requiresAuth: true,
      groups: ['redakteur', 'admin']
    }
  },
  {
    path: '/approvals',
    name: 'approvals',
    component: () => import('../views/ApprovalView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/useradministration',
    name: 'useradministration',
    component: () => import('../views/UseradminView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/collections',
    name: 'collections',
    component: () => import('../views/CollectionView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('../views/StatisticView.vue'),
  },
  {
    path: '/passreset',
    name: 'passreset',
    component: () => import('../views/PassresetView.vue'),
    meta: {
      layout: 'login'
    }
  },
  {
    path: '/external/:hashtoken',
    name: 'external',
    component: () => import('../views/ExternalView.vue'),
    meta: {
      layout: 'external'
    }
  }
]

const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // checks if a route requires auth
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.auth.token) {
      console.log('route auth check', 1)
      next({ path: '/login' });
    } else {
      console.log('route auth check', 2)
      store.dispatch('getuser', store.state.auth.token).then(() => {
        let commongroups = [];
        if(to.meta.groups) {
          let userset = new Set(store.state.currentuser.user.groups.element)
          commongroups = to.meta.groups.filter(item => userset.has(item));

          if(commongroups.length <= 0) {
            router.push({name: 'home'})
            console.log('usergroup - Redirect user to Base');
            return;
          }
        }
        console.log('usergroup', store.state.currentuser.user.displayname, to.meta.groups, commongroups, store.state.currentuser.user.groups.element);
      })
      next();
    }
  } else {
    console.log('route auth check', 3)
    next();
  }
});


export default router
