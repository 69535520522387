<template>
  <div class="relative" ref="dropdown" :class="className">
    <div class="file_details" @click.stop="toggleDropdown">
      <slot name="button">
        <span class=""><img src="@/assets/icons/dots.svg" class="!w-8 h-auto cursor-pointer"/></span>
      </slot>
    </div>
    <div
        v-show="open"
        class="dropdownmenu z-30 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 bg-wuh-secondary"
        :class="boxPos"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
        style=""
    >
      <div class="dropdown-arrow-up"></div>
      <div class="dropdown-arrow-right"></div>
      <div class="py-1" role="none">
        <slot></slot>
      </div>
    </div>
  </div>

</template>
<script>

import emitter from "@/addons/event-bus";
import axios from "axios";

export default {
  components: {},
  props: {
    item: {
      type: [Object, Array],
      required: true
    },
    className: {
      type: String
    },
    boxPos: {
      type: String
    }
  },
  data() {
    return {
      open: false,
      showMoveModal: false,
      targetdirectories: [],
      targetdirectory: null,
      isSingle: false,
      isMulti: false,
    }
  },
  computed: {
    itemIsMulti() {
      let res = false
      if (typeof this.item === 'object' && !Array.isArray(this.item)) {
        res = false
      }
      else if (Array.isArray(this.item)) {
        res = true
      }
      return res
    },
  },
  methods: {
    download() {

      let fileId = []
      if(this.itemIsMulti) {
        console.locallog('MultiItem', this.item)
        this.item.forEach((item) => {
          fileId.push(item.fileid)
        })
      } else {
        fileId = [this.item.fileid]
        console.locallog('SingleItem', this.item)
      }

      console.locallog('Initiate download', this.item)
      axios.post(process.env.VUE_APP_APP_APIURL + '/api/files/downloadbyid',
          {
            fileids: fileId
          },
          {
            responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // check if it's zip file or not, which you can determine from the response headers
        let contentType = response.headers['content-type'];
        let contentDisposition = response.headers['content-disposition'];

        let fileExtension = contentType.split('/')[1]; // change this if extension extraction is different
        // Assuming fileId can be used as fileName
        let fileName = `${fileId}.${fileExtension}`;

        console.locallog('Disposition', contentDisposition)
        if (contentDisposition) {
          let filenameToken = "filename=";
          let startIdx = contentDisposition.indexOf(filenameToken);
          if (startIdx !== -1) {
            let endIdx = contentDisposition.indexOf(";", startIdx);
            if (endIdx === -1) { endIdx = undefined; } // If no semicolon exists, the filename extends to the end of the string
            fileName = contentDisposition.slice(startIdx + filenameToken.length, endIdx);
          }
        }


        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      });


    },
    async movefile() {
      console.locallog('Move File', this.item)
      this.showMoveModal = true
      this.closeDropdown();

    },
    startmovefiles() {
      console.locallog('Dateien ', this.item, 'verschiebene nach ', this.targetdirectory)
      this.fileid = this.item
    },

    setactiveimage(index) {
      this.timer = setTimeout(() => {
        if (!this.prevent) {
          console.locallog('single click detected');
          if (!this.$store) {
            console.error("Vuex store is not defined.");
            return;
          }
          try {
            this.$store.commit('singleimage/SET_SINGLE_IMAGE', index);
            this.closeDropdown();
          } catch (error) {
            console.error("Error committing Vuex mutation:", error);
          }
        }
        this.prevent = false;
      }, this.delay);
    },
    removefile() {
      console.locallog('Remove File', this.item)
    },


    doaction() {
        console.locallog('item', this.item)
    },
    toggleDropdown() {
      if (!this.open) {
        this.open = true;
        // add a global click handler
        document.addEventListener('click', this.clickOutsideHandler);
        // emit an event to close other dropdowns
        emitter.emit('dropdownOpen', this);

        if(this.itemIsMulti) {
          console.locallog('MultiItem', this.item)
        } else {
          console.locallog('SingleItem', this.item)
        }
      } else {
        this.closeDropdown();
      }
    },
    closeDropdown() {
      this.open = false;
      // remove the global click handler when dropdown closes
      document.removeEventListener('click', this.clickOutsideHandler);
    },
    clickOutsideHandler(e) {
      // if clicked element is not inside the dropdown
      console.locallog('e', e)
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(e.target)) {
        this.closeDropdown();
      }
    },
  },
  mounted() {
    console.locallog('Fetch folders')

    this.fileid = this.item
    // this.$store.commit('globalstore/SET_LOADING', true)
    // axios.post(process.env.VUE_APP_APP_APIURL + '/api/files/gettargetdirectories',{})
    //     .then((response) => {
    //       this.targetdirectories = response.data.data.folders
    //
    //       this.$store.commit('globalstore/SET_LOADING', false)
    //     })
    //     .catch(() => {
    //       this.$store.commit('globalstore/SET_LOADING', false)
    //     });
  },
  created() {
    emitter.on('dropdownOpen', dropdown => {
      if (dropdown !== this) {
        this.closeDropdown();
      }
    });
  },
  beforeUnmount() {
    emitter.all.clear();
  }
}
</script>

<style scoped>

.dropdownmenu {
  top:10px;
  top:25px;
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  z-index: 1;
}

.dropdownmenu .dropdown-arrow-right,
.dropdownmenu.posleft .dropdown-arrow-up {
  display:none;
}

.dropdownmenu .dropdown-arrow-up {
  @apply absolute w-4 h-4 bg-white -mt-2 transform rotate-45 left-1/2 -ml-2 border-t border-l border-gray-200 bg-wuh-secondary;
}


.dropdownmenu.posleft .dropdown-arrow-right {
  @apply absolute w-4 h-4 bg-white -mt-2 transform rotate-45 right-0 border-t border-l border-gray-200 bg-wuh-secondary;
  right: -0.5rem;
  margin-top: 0.5rem;
  display:block;
}



.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdownmenu.posleft {
  top:0;
  right: 3rem;
  left: auto !important;
  transform: none !important;
}



.file_details span {
  @apply text-[72px] leading-[5px] p-0 m-0 ;
  img {
    @apply w-auto h-auto;
  }
}
</style>
