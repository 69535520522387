<template>

<!--  <transition name="slide-fade" class="duration-500">-->
  <div class="flex flex-col min-h-screen bg-wuh-secondary text-center gap-3 pt-10 px-5 " :class="opensidebar ? 'w-[16rem] min-w-[16rem] max-w-[16rem]' : ''">
      <b>
        <span v-if="opensidebar">{{$store.state.currentuser.user.displayname}}</span>
        &nbsp;
      </b>
      <a href="#" class="w-10 self-end" v-if="!opensidebar" @click="opensidebar=!opensidebar">
        <img src="@/assets/icons/arrow-right.svg" />
      </a>
      <a href="#" class="w-10 self-end" v-if="opensidebar" @click="opensidebar=!opensidebar">
        <img src="@/assets/icons/arrow-left.svg" />
      </a>
    <RouterLink :to="{name: 'home'}" class="sidebarlink">
        <img src="@/assets/icons/home.svg" />
        <span class="text-wuh-primaryfontcolor" v-if="opensidebar">Startseite</span>
    </RouterLink>
    <RouterLink :to="{name: 'myimages'}" class="sidebarlink" v-if="$store.state.currentuser.user.role == 'admin' || $store.state.currentuser.user.role == 'redakteur'">
        <img src="@/assets/icons/my-images.svg" />
        <span class="text-wuh-primaryfontcolor" v-if="opensidebar">Meine Bilder</span>
    </RouterLink>
    <RouterLink :to="{name: 'myfolder'}" class="sidebarlink" v-if="$store.state.currentuser.user.role == 'admin' || $store.state.currentuser.user.role == 'redakteur'">
        <img src="@/assets/icons/my-folder.svg" />
        <span class="text-wuh-primaryfontcolor" v-if="opensidebar">Meine Ordner</span>
    </RouterLink>
    <RouterLink :to="{name: 'collections'}" class="sidebarlink" v-if="$store.state.currentuser.user.role == 'admin' || $store.state.currentuser.user.role == 'redakteur'">
        <img src="@/assets/icons/collections.svg" />
        <span class="text-wuh-primaryfontcolor" v-if="opensidebar">Meine Kollektionen</span>
    </RouterLink>
    <div class="w-full px-2.5">
      <div class="w-full h-0.5 bg-white"></div>
    </div>

    <RouterLink :to="{name: 'myshares'}" class="sidebarlink" v-if="$store.state.currentuser.user.role == 'admin' || $store.state.currentuser.user.role == 'redakteur'">
        <img src="@/assets/icons/shared.svg" />
        <span class="text-wuh-primaryfontcolor" v-if="opensidebar">Von dir geteilt</span>
    </RouterLink>

    <RouterLink :to="{name: 'approvals'}" class="sidebarlink" v-if="$store.state.currentuser.user.role == 'admin'">
        <img src="@/assets/icons/open-approval.svg" />
        <span class="text-wuh-primaryfontcolor" v-if="opensidebar">Freigaben</span>
    </RouterLink>

    <RouterLink :to="{name: 'useradministration'}" class="sidebarlink" v-if="$store.state.currentuser.user.role == 'admin'">
        <img src="@/assets/icons/user-admin.svg" />
        <span class="text-wuh-primaryfontcolor" v-if="opensidebar">Benutzer</span>
    </RouterLink>

    <RouterLink :to="{name: 'statistics'}" class="sidebarlink" v-if="$store.state.currentuser.user.role == 'admin'">
        <img src="@/assets/icons/statistics.svg" />
        <span class="text-wuh-primaryfontcolor" v-if="opensidebar">Statistik</span>
    </RouterLink>
    <a href="#" @click="logout" class="sidebarlink mt-20">
        <img src="@/assets/icons/close.svg" />
        <span class="text-wuh-primaryfontcolor" v-if="opensidebar">Abmelden</span>
    </a>
  </div>
<!--  </transition>-->

</template>

<script>
import homeView from "@/views/HomeView.vue";
import store from "@/store";

export default {
  name: 'SiteNavigation',
  computed: {
    homeView() {
      return homeView
    }
  },
  data() {
    return {
      opensidebar: true
    }
  },
  methods: {
    logout() {
      store.state.auth.token = false
    }
  }
}
</script>

<style type="text/css">
.sidebarlink {
  @apply w-full flex;
}
.sidebarlink img {
  @apply flex w-14;
  @apply flex max-w-14;
}
.sidebarlink > span {
  @apply flex pl-5 items-center text-wuh-primaryfontcolor;
}
.sidebarlink.router-link-active span {
  @apply font-bold;
}



</style>
