<template>
  <div class="modal-overlay z-[500]" v-if="show" @click="$emit('close')">
    <div class="modal relative" @click.stop>
      <button class="close-button" @click="$emit('close')"><img src="@/assets/icons/close.svg" /></button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalView",
  props: ["show"],
  data() {
    return {}
  },

}
</script>

<style lang="scss" scoped>
  .close-button {
    @apply absolute top-1 right-1 h-10 w-10;
  }

  .modal {
    background: #E7ECE8;
    @apply px-8 py-3;
  }


</style>
