<template>
  <div class="fixed bottom-0 left-0 w-full bg-wuh-green h-10 z-50 footerstyle">
    <div class="flex justify-between ">
      <div class="flex p-2 text-white">
        &copy; Wald und Holz NRW
      </div>
      <div class="flex ">

        <ul class="flex justify-self-end w-auto text-white">
          <li class="p-2 inline-flex border-right">
            <a href="#" @click.prevent="showHelpModal = true" class="text-white">Hilfe</a>
          </li>
          <li class="p-2 inline-flex border-right">
            <a href="#" @click.prevent="showImpressumModal = true" class="text-white">Impressum</a>
          </li>
          <li class="p-2 inline-flex ">
            <a href="#" @click.prevent="showDatenschutzModal = true" class="text-white">Datenschutz</a>
          </li>
        </ul>
      </div>
    </div>
  </div>


  <ModalView :show="showHelpModal" @close="showHelpModal = false" >
    <perfect-scrollbar class="flex-grow pt-10 px-10 pb-10 relatived max-h-screen" ref="mainscroll">
      <div class="w-[50vw]">
        <h2 class="font-bold size-6 w-full mb-2">Hilfe</h2>
        <p>
          Bei Fragen wenden Sie sich an:<br />
        </p>
        <p>
          Nicole Fiegler<br />
          Albrecht-Thaer-Straße 34<br />
          48147 Münster<br />
          Tel.: 0251 / 91797 - 217<br />
          Mobil.: 0151 / 44255881<br />
          E-Mail: <a href="mailto:nicole.fiegler@wald-und-holz.nrw.de">nicole.fiegler@wald-und-holz.nrw.de</a><br />
        </p>
        <p>
          Online-Redaktion<br />
          E-Mail: <a href="mailto:onlineredaktion@wald-und-holz.nrw.de">onlineredaktion@wald-und-holz.nrw.de</a>
        </p>
      </div>
    </perfect-scrollbar>
  </ModalView>
  <ModalView :show="showImpressumModal" @close="showImpressumModal = false" >
    <perfect-scrollbar class="flex-grow pt-10 px-10 pb-10 relatived max-h-screen" ref="mainscroll">
      <div class="w-[75vw]">
        <h2 class="font-bold size-6 w-full mb-2 text-2xl">Impressum</h2>
        <p>
          Wald und Holz NRW ist ein Landesbetrieb im Sinne des § 14a Landesorganisationsgesetz NRW mit Sitz in Münster. Die Leitung des Landesbetriebes obliegt Tim Scherer und Thomas Kämmerling. Die Leiter vertreten das Land NRW in rechtlichen Angelegenheiten des Landesbetriebes gerichtlich und außergerichtlich. Aufsichtsbehörde über den Landesbetrieb ist das Ministerium für Landwirtschaft und Verbraucherschutz des Landes Nordrhein-Westfalen. Dieses übt die Dienst- und Fachaufsicht aus.
        </p>

        <h3 class="font-bold size-4 w-full mb-4 mt-4">Urheberrecht und Haftungsausschluss</h3>
        <h4 class="font-bold size-4 w-full mb-2 mt-2">Inhalt des Onlineangebotes</h4>
        <p>
          Wald und Holz NRW übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Auch können wir nicht garantieren, dass unsere Sammlung oder die verlinkten Seiten keine Viren enthalten. Haftungsansprüche gegen Wald und Holz NRW oder seine Partner, die sich auf Schäden materieller oder ideeller Art beziehen, welche durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens von Wald und Holz NRW oder seiner Partner kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.Alle Angebote sind freibleibend und unverbindlich. Wald und Holz NRW behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
        </p>

        <h4 class="font-bold size-4 w-full mb-2 mt-2">Verweise und Links</h4>
        <p>
          Eine Gewähr oder jegliche Haftung für Link-Verweise und insbesondere die Informationen der verlinkten Internetseiten von Drittanbietern, die außerhalb des Verantwortungsbereiches von Wald und Holz NRW liegen, kann nicht übernommen werden. Die Rechte an diesen Seiten sowie die Verantwortlichkeit für deren Inhalt liegen ausschließlich beim Drittanbieter. Wir distanzieren uns von jeglichem Inhalt. Dies gilt insbesondere für Inhalte, deren Verbreitung nach deutschem und ausländischem Recht verboten ist und deren Beihilfe zur Verbreitung strafrechtlich verfolgt wird. Diese Einschränkung gilt gleichermaßen auch für Fremdeinträge in Veranstaltungshinweisen der Landesforstvereine.
        </p>

        <h4 class="font-bold size-4 w-full mb-2 mt-2">Urheberrecht</h4>
        <p>
          Wald und Holz NRW ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen. Das Copyright für veröffentlichte, von Wald und Holz NRW selbst erstellte Objekte bleibt allein bei Wald und Holz NRW. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung Wald und Holz NRW nicht gestattet.
        </p>

        <h4 class="font-bold size-4 w-full mb-2 mt-2">Rechtswirksamkeit dieses Haftungsausschlusses</h4>
        <p>
          Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
        </p>

        <h4 class="font-bold size-4 w-full mb-2 mt-2">Wichtiger Hinweis zu allen Links und nicht zu uns gehörenden Seiten</h4>
        <p>
          Mit Urteil vom 12. Mai 1998 - 312 O 85/98 - "Haftung für Links" hat das Landgericht (LG) in Hamburg entschieden, dass man durch die Anbringung eines Links, die Inhalte der gelinkten Seite ggf. mit zu verantworten hat. Dies kann - so das LG - nur dadurch verhindert werden, indem man sich ausdrücklich von diesen Seiten distanziert. Hiermit distanzieren wir uns ausdrücklich von allen Inhalten aller gelinkten Seiten, die nicht über uns entstanden sind.
        </p>
      </div>
    </perfect-scrollbar>
  </ModalView>
  <ModalView :show="showDatenschutzModal" @close="showDatenschutzModal = false" >
    <perfect-scrollbar class="flex-grow pt-10 px-10 pb-10 relatived max-h-screen" ref="mainscroll">
      <div class="w-[75vw]">
        <h2 class="font-bold w-full mb-4 text-2xl">Datenschutzerklärung</h2>
        <p>
          Der Landesbetrieb Wald und Holz NRW nimmt den Schutz personenbezogener Daten sehr ernst. Wir haben technische und organisatorische Maßnahmen getroffen, die sicherstellen, dass die Vorschriften über den Datenschutz von uns und von externen Dienstleistern beachtet werden. Es erfolgt keine Speicherung von ungekürzten IP-Adressen in den Protokolldateien der Webserver. Die Anonymisierung der IP-Adressen wird bereits unmittelbar nach der Erbringung des Dienstes vorgenommen. Die statistische Auswertung anonymisierter Datensätze bleibt vorbehalten. Die Angabe von persönlichen oder geschäftlichen Daten wie E-Mail-Adresse, Name und Anschrift zur Durchführung von Serviceleistungen wie zum Beispiel der Kontaktaufnahme durch die Nutzerinnen und Nutzer sind ausdrücklich freiwillig. Die vertrauliche Behandlung dieser persönlichen Daten ist für uns selbstverständlich. Sie werden in keinem Fall an Dritte weitergegeben. Die Erhebung und Verarbeitung erfolgt im Rahmen der datenschutzrechtlichen Bestimmungen. Alle zur Durchführung der Datenverarbeitung eingesetzten Mitarbeiterinnen und Mitarbeiter sind zur Einhaltung des Datengeheimnisses verpflichtet.
        </p>
        <p>
          Die Website benutzt Matomo (früherer Name: Piwik), ein Open-Source-Softwaretool zur Webanalyse. Dabei handelt es sich um Textdateien, die auf dem PC einer betroffenen Person gespeichert werden. Zur Analyse des Verhaltens von Besuchern werden Daten erhoben, gesammelt und ausgewertet. Es werden unter anderen Daten darüber erfasst, von welcher Internetseite eine betroffene Person auf die Website gekommen ist (sogenannter Referrer), auf welche (Unter-)Seiten zugegriffen oder wie oft und wie lange eine (Unter-) Seite betrachtet wurde. Die Webanalyse dient der Optimierung des Internetangebotes. Die gewonnenen Daten und Informationen werden unter anderem dazu genutzt, die Nutzung der Internetseite auszuwerten, um Online-Reports zusammenzustellen, die die Aktivitäten auf der Website aufzeigen. Rechtsgrundlage für die vorstehend genannten Verarbeitungen ist Art. 6 Abs. 1 S. 1 lit. f) DSGVO.
        </p>
        <p>
          Matomo setzt ein Cookie auf dem System der betroffenen Person ein. Mit der Setzung des Cookies wird eine Analyse der Benutzung der Website ermöglicht. Es werden personenbezogene Informationen, beispielsweise die Zugriffszeit, der Ort und die Häufigkeit der Besuche auf der Website gespeichert. Diese personenbezogenen Daten werden gespeichert und die IP-Adresse vor der Speicherung anonymisiert. Eine Identifizierung der Besucher ist nicht möglich.
        </p>
        <p>
          Die Software wird auf einem von uns im Rahmen einer Auftragsverarbeitung angemieteten Server betrieben und die datenschutzrechtlich relevanten Logdateien werden auf diesem Server gespeichert. Die anonymen Statistikdaten werden getrennt von eventuell angegebenen personenbezogenen Daten gespeichert und lassen keine Rückschlüsse auf eine Person zu.
        </p>
        <p>
          Über eine Änderung der Browsereinstellungen können Sie die Speicherung von Cookies auf Ihrem Rechner unterbinden. Wir weisen darauf hin, dass Sie in diesem Fall gegebenenfalls nicht alle Funktionen der Website nutzen können.
        </p>
        <p>
          Wenn Sie mit der Speicherung und Nutzung Ihrer Daten nicht einverstanden sind, können Sie die Speicherung und Nutzung hier deaktivieren. In diesem Fall wird in Ihrem Browser ein Opt-Out-Cookie hinterlegt, der verhindert, dass Matomo Nutzungsdaten speichert. Wenn Sie Ihre Cookies löschen, hat dies zur Folge, dass auch das Matomo Opt-Out-Cookie gelöscht wird. Das Opt-Out muss bei einem erneuten Besuch unserer Seite wieder aktiviert werden.
        </p>
        <p>
          Klicken Sie hierzu auf den folgenden Link, um den Matomo-Deaktivierungs-Cookie in Ihrem Browser abzulegen: Download Deaktivierung-Cookie
        </p>
        <h4 class="text-2xl">
          Name und Kontakt des Verantwortlichen gemäß Artikel 4 Abs. 7 DSGVO
        </h4>
        <p>
          Landesbetrieb Wald und Holz Nordrhein-Westfalen<br />
          Albrecht-Thaer-Straße 34<br />
          48147 Münster
        </p>
        <p>
          <strong>Datenschutzbeauftragter</strong><br />
          Yuri Kranz<br />
          Justitiariat<br />
          Albrecht-Thaer-Str. 34<br />
          48147 Münster<br />
          E-Mail: <a href="mailto:yuri.kranz@wald-und-holz.nrw.de">yuri.kranz@wald-und-holz.nrw.de</a>
        </p>
        <h4 class="text-2xl">
          Sicherheit und Schutz Ihrer personenbezogenen Daten
        </h4>
        <p>
          Wir betrachten es als unsere vorrangige Aufgabe, die Vertraulichkeit der von Ihnen bereitgestellten personenbezogenen Daten zu wahren und diese vor unbefugten Zugriffen zu schützen. Deshalb wenden wir äußerste Sorgfalt und modernste Sicherheitsstandards an, um einen maximalen Schutz Ihrer personenbezogenen Daten zu gewährleisten.
        </p>
        <p>
          Als Behörde unterliegen wir den Bestimmungen der europäischen Datenschutzgrundverordnung (DSGVO) und den Regelungen des Bundesdatenschutzgesetzes (BDSG). Wir haben technische und organisatorische Maßnahmen getroffen, die sicherstellen, dass die Vorschriften über den Datenschutz sowohl von uns, als auch von unseren externen Dienstleistern beachtet werden.
        </p>
        <p>
          Der Gesetzgeber fordert, dass personenbezogene Daten auf rechtmäßige Weise, nach Treu und Glauben und in einer für die betroffene Person nachvollziehbaren Weise verarbeitet werden („Rechtmäßigkeit, Verarbeitung nach Treu und Glauben, Transparenz“).
        </p>
        <h4 class="text-2xl">
          Rechtmäßigkeit der Verarbeitung
        </h4>
        <p>
          Die Verarbeitung personenbezogener Daten ist nur rechtmäßig, wenn für die Verarbeitung eine Rechtsgrundlage besteht. Rechtsgrundlage für die Verarbeitung können gemäß Artikel 6 Abs. 1 lit. a – f DSGVO insbesondere sein:
          <ul>
            <li>Die betroffene Person hat ihre Einwilligung zu der Verarbeitung der sie betreffenden personenbezogenen Daten für einen oder mehrere bestimmte Zwecke gegeben;</li>
            <li>die Verarbeitung ist für die Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen Person erfolgen;</li>
            <li>die Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen, insbesondere dann, wenn es sich bei der betroffenen Person um ein Kind handelt.</li>
          </ul>
        </p>
        <h4 class="text-2xl">
          Information über die Erhebung personenbezogener Daten
        </h4>
        <p>
          (1) Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei Nutzung unserer Website. Personenbezogene Daten sind z.B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.
        </p>
        <p>
          (2) Bei einer Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder die Verarbeitung wird eingeschränkt, falls gesetzliche Aufbewahrungspflichten bestehen.
        </p>
        <p>
          <strong>Erhebung personenbezogener Daten bei Besuch unserer Website</strong><br />
          Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren oder uns anderweitig Informationen übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie unsere Website betrachten möchten, erheben wir die folgenden Daten, die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO):
        </p>
        <p>
          <ul>
            <li>IP-Adresse</li>
            <li>Datum und Uhrzeit der Anfrage</li>
            <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
            <li>Inhalt der Anforderung (konkrete Seite)</li>
            <li>Zugriffsstatus/HTTP-Statuscode</li>
            <li>jeweils übertragene Datenmenge</li>
            <li>Website, von der die Anforderung kommt</li>
            <li>Browser</li>
            <li>Betriebssystem und dessen Oberfläche</li>
            <li>Sprache und Version der Browsersoftware.</li>
          </ul>
        </p>
        <p>
          <strong>Einsatz von Cookies</strong><br />
          (1) Zusätzlich zu den zuvor genannten Daten werden bei der Nutzung unserer Website Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt, bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen.
        </p>
        <p>
          (2) Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im Folgenden erläutert werden:
        </p>
        <p>
          - Transiente Cookies (dazu a.)
          - Persistente Cookies (dazu b.).
        </p>
        <p>
          a. Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schließen.
        </p>
        <p>
          b. Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.
        </p>
        <p>
          c. Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z.B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Sog. „Third Party Cookies“ sind Cookies, die durch einen Dritten gesetzt wurden, folglich nicht durch die eigentliche Website auf der man sich gerade befindet. Wir weisen Sie darauf hin, dass Sie durch die Deaktivierung von Cookies eventuell nicht alle Funktionen dieser Website nutzen können.
        </p>
        <p>
          <strong>Weitere Funktionen und Angebote unserer Website</strong><br />
          (1) Neben der rein informatorischen Nutzung unserer Website bieten wir verschiedene Leistungen an, die Sie bei Interesse nutzen können. Dazu müssen Sie in der Regel weitere personenbezogene Daten angeben, die wir zur Erbringung der jeweiligen Leistung nutzen und für die die zuvor genannten Grundsätze zur Datenverarbeitung gelten.
        </p>
        <p>
          (2) Teilweise bedienen wir uns zur Verarbeitung Ihrer Daten externer Dienstleister. Diese wurden von uns sorgfältig ausgewählt und beauftragt, sind an unsere Weisungen gebunden und werden regelmäßig kontrolliert.
        </p>
        <p>
          (3) Weiterhin können wir Ihre personenbezogenen Daten an Dritte weitergeben, wenn Aktionsteilnahmen, Gewinnspiele, Vertragsabschlüsse oder ähnliche Leistungen von uns gemeinsam mit Partnern angeboten werden. Nähere Informationen hierzu erhalten Sie bei Angabe Ihrer personenbezogenen Daten oder untenstehend in der Beschreibung des Angebotes.
        </p>
        <p>
          (4) Soweit unsere Dienstleister oder Partner ihren Sitz in einem Staat außerhalb des Europäischen Wirtschaftsraumen (EWR) haben, informieren wir Sie über die Folgen dieses Umstands in der Beschreibung des Angebotes.
        </p>
        <p>
          <strong>Newsletter</strong><br />
          (1) Mit Ihrer Einwilligung können Sie unseren Newsletter abonnieren, mit dem wir Sie über unsere aktuellen interessanten Angebote informieren. Die beworbenen Waren und Dienstleistungen sind in der Einwilligungserklärung benannt.
        </p>
        <p>
          (2) Für die Anmeldung zu unserem Newsletter verwenden wir das sog. Double-Opt-in-Verfahren. Das heißt, dass wir Ihnen nach Ihrer Anmeldung eine E-Mail an die angegebene E-Mail-Adresse senden, in welcher wir Sie um Bestätigung bitten, dass Sie den Versand des Newsletters wünschen. Wenn Sie Ihre Anmeldung nicht innerhalb von 24 Stunden bestätigen, werden Ihre Informationen gesperrt und nach einem Monat automatisch gelöscht. Darüber hinaus speichern wir jeweils Ihre eingesetzten IP-Adressen und Zeitpunkte der Anmeldung und Bestätigung. Zweck des Verfahrens ist, Ihre Anmeldung nachweisen und ggf. einen möglichen Missbrauch Ihrer persönlichen Daten aufklären zu können.
        </p>
        <p>
          (3) Pflichtangabe für die Übersendung des Newsletters ist allein Ihre E-Mail-Adresse. Die Angabe weiterer, gesondert markierter Daten ist freiwillig und wird verwendet, um Sie persönlich ansprechen zu können. Nach Ihrer Bestätigung speichern wir Ihre E-Mail-Adresse zum Zweck der Zusendung des Newsletters. Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. a DSGVO.
        </p>
        <p>
          (4) Ihre Einwilligung in die Übersendung des Newsletters können Sie jederzeit widerrufen und den Newsletter abbestellen. Den Widerruf können Sie durch Klick auf den in jeder Newsletter-E-Mail bereitgestellten Link, über das Formular der Website, per E-Mail an info@waldundholz.nrw.de oder durch eine Nachricht an die im Impressum angegebenen Kontaktdaten erklären.
        </p>

        <h4 class="text-2xl">Kinder</h4>
        <p>
          Unser Angebot richtet sich grundsätzlich an Erwachsene. Personen unter 18 Jahren sollten ohne Zustimmung der Eltern oder Erziehungsberechtigten keine personenbezogenen Daten an uns übermitteln.
        </p>

        <h4 class="text-2xl">Rechte der betroffenen Person</h4>
        <p>
          <strong>(1) Widerruf der Einwilligung</strong><br />
          Sofern die Verarbeitung der personenbezogenen Daten auf einer erteilten Einwilligung beruht, haben Sie jederzeit das Recht, die Einwilligung zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
          Für die Ausübung des Widerrufsrechts können Sie sich jederzeit an uns unter den oben angegebenen Kontaktdaten wenden.
        </p>
        <p>
          <strong>(2) Recht auf Bestätigung</strong><br />
          Sie haben das Recht, von dem Verantwortlichen eine Bestätigung darüber zu verlangen, ob wir sie betreffende personenbezogene Daten verarbeiten. Die Bestätigung können Sie jederzeit unter den oben genannten Kontaktdaten verlangen.
        </p>
        <p>
          <strong>(3) Auskunftsrecht</strong><br />
          Sofern personenbezogene Daten verarbeitet werden, können Sie jederzeit Auskunft über diese personenbezogenen Daten und über folgenden Informationen verlangen:
        </p>
        <p>
          a. die Verarbeitungszwecke;<br />
          b. den Kategorien personenbezogener Daten, die verarbeitet werden;<br />
          c. die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen;<br />
          d. falls möglich, die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer;<br />
          e. das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;<br />
          f. das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;<br />
          g. wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden, alle verfügbaren Informationen über die Herkunft der Daten;<br />
          h. das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Artikel 22 Absätze 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.<br />
        </p>
        <p>
          Werden personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt, so haben Sie das Recht, über die geeigneten Garantien gemäß Artikel 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden. Wir stellen eine Kopie der personenbezogenen Daten, die Gegenstand der Verarbeitung sind, zur Verfügung. Für alle weiteren Kopien, die Sie Person beantragen, können wir ein angemessenes Entgelt auf der Grundlage der Verwaltungskosten verlangen. Stellen Sie den Antrag elektronisch, so sind die Informationen in einem gängigen elektronischen Format zur Verfügung zu stellen, sofern er nichts anderes angibt. Das Recht auf Erhalt einer Kopie gemäß Absatz 3 darf die Rechte und Freiheiten anderer Personen nicht beeinträchtigen.
        </p>
        <p>
          (4) Recht auf Berichtigung
          Sie haben das Recht, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.
        </p>
        <p>
          <strong>(5) Recht auf Löschung („Recht auf vergessen werden“)</strong><br />
          Sie haben das Recht, von dem Verantwortlichen zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, und wir sind verpflichtet, personenbezogene Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
        </p>
        <p>
          a. Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.<br />
          b. Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung gemäß Artikel 6 Absatz 1 Buchstabe a oder Artikel 9 Absatz 2 Buchstabe a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.<br />
          c. Die betroffene Person legt gemäß Artikel 21 Absatz 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die betroffene Person legt gemäß Artikel 21 Absatz 2 DSGVO Widerspruch gegen die Verarbeitung ein.<br />
          d. Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.<br />
          e. Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.<br />
          f. Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Artikel 8 Absatz 1 DSGVO erhoben.<br />
        </p>
        <p>
          Hat der Verantwortliche die personenbezogenen Daten öffentlich gemacht und ist er gemäß Absatz 1 zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass eine betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt hat.
        </p>
        <p>
          Das Recht auf Löschung („Recht auf vergessen werden“) besteht nicht, soweit die Verarbeitung erforderlich ist:
          <ul>
            <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
            <li>zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;</li>
            <li>aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Artikel 9 Absatz 2 Buchstaben h und i sowie Artikel 9 Absatz 3 DSGVO;</li>
            <li>für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gemäß Artikel 89 Absatz 1 DSGVO, soweit das in Absatz 1 genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder</li>
            <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
          </ul>
        </p>
        <p>
          <strong>(6)  Recht auf Einschränkung der Verarbeitung</strong><br />
          Sie haben das Recht, von uns die Einschränkung der Verarbeitung ihrer personenbezogenen Daten zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:
        </p>
        <p>
          a. die Richtigkeit der personenbezogenen Daten von der betroffenen Person bestritten wird, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen,<br />
          b. die Verarbeitung unrechtmäßig ist und die betroffene Person die Löschung der personenbezogenen Daten ablehnt und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangt;<br />
          c. der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, die betroffene Person sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigt, oder<br />
          d. die betroffene Person Widerspruch gegen die Verarbeitung gemäß Artikel 21 Absatz 1 DSGVO eingelegt hat, solange noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen Person überwiegen.<br />
        </p>
        <p>
          Wurde die Verarbeitung gemäß den oben genannten Voraussetzungen eingeschränkt, so werden diese personenbezogenen Daten – von ihrer Speicherung abgesehen – nur mit Einwilligung der betroffenen Person oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet.
        </p>
        <p>
          Um das Recht auf Einschränkung der Verarbeitung geltend zu machen, kann sich die betroffene Person jederzeit an uns unter den oben angegebenen Kontaktdaten wenden.
        </p>
        <p>
          <strong>(7) Recht auf Datenübertragbarkeit</strong><br />
          Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und Sie haben das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern:
        </p>
        <p>
          a. die Verarbeitung auf einer Einwilligung gemäß Artikel 6 Absatz 1 Buchstabe a oder Artikel 9 Absatz 2 Buchstabe a oder auf einem Vertrag gemäß Artikel 6 Absatz 1 Buchstabe b DSGVO beruht und<br />
          b. die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
        </p>
        <p>
          Bei der Ausübung des Rechts auf Datenübertragbarkeit gemäß Absatz 1 haben Sie das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen zu einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Die Ausübung des Rechts auf Datenübertragbarkeit lässt das Recht auf Löschung („Recht auf Vergessen werden“) unberührt. Dieses Recht gilt nicht für eine Verarbeitung, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
        </p>
        <p>
          <strong>(8) Widerspruchsrecht</strong><br />
          Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Artikel 6 Absatz 1 Buchstaben e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Der Verantwortliche verarbeitet die personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
        </p>
        <p>
          Werden personenbezogene Daten verarbeitet, um Direktwerbung zu betreiben, so haben SIe das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
        </p>
        <p>
          Im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft könne Sie ungeachtet der Richtlinie 2002/58/EG Ihr Widerspruchsrecht mittels automatisierter Verfahren ausüben, bei denen technische Spezifikationen verwendet werden.
        </p>
        <p>
          Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Sie betreffende Verarbeitung Sie betreffender personenbezogener Daten, die zu wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß Artikel 89 Absatz 1 erfolgt, Widerspruch einzulegen, es sei denn, die Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe erforderlich.
        </p>
        <p>
          Das Widerspruchsrecht können Sie jederzeit ausüben, indem Sie sich an den jeweiligen Verantwortlichen wenden.
        </p>
        <p>
          <strong>(9) Automatisierte Entscheidungen im Einzelfall einschließlich Profiling</strong><br />
          Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung:
        </p>
        <p>
          a. für den Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen Person und dem Verantwortlichen erforderlich ist,<br />
          b. aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung der Rechte und Freiheiten sowie der berechtigten Interessen der betroffenen Person enthalten oder<br />
          c. mit ausdrücklicher Einwilligung der betroffenen Person erfolgt.<br />
        </p>
        <p>
          Der Verantwortliche trifft angemessene Maßnahmen, um die Rechte und Freiheiten sowie die berechtigten Interessen der betroffenen Person zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
        </p>
        <p>
          Dieses Recht kann die betroffene Person jederzeit ausüben, indem sie sich an den jeweiligen Verantwortlichen wendet.
        </p>
        <p>
          <strong>(10) Recht auf Beschwerde bei einer Aufsichtsbehörde</strong><br />
          Sie haben zudem, unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs, das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn die betroffene Person der Ansicht ist, dass die Verarbeitung der sie betreffenden personenbezogenen Daten gegen diese Verordnung verstößt.
        </p>
        <p>
          <strong>(11) Recht auf wirksamen gerichtlichen Rechtsbehelf</strong><br />
          Sie haben unbeschadet eines verfügbaren verwaltungsrechtlichen oder außergerichtlichen Rechtsbehelfs einschließlich des Rechts auf Beschwerde bei einer Aufsichtsbehörde gemäß Artikel 77 DSGVO das Recht auf einen wirksamen gerichtlichen Rechtsbehelf, wenn sie der Ansicht ist, dass die ihr aufgrund dieser Verordnung zustehenden Rechte infolge einer nicht im Einklang mit dieser Verordnung stehenden Verarbeitung ihrer personenbezogenen Daten verletzt wurden.
        </p>

        <h4 class="text-2xl">Einbindung von Google Maps</h4>
        <p>
          (1) Auf dieser Website nutzen wir das Angebot von Google Maps. Dadurch können wir Ihnen interaktive Karten direkt in der Website anzeigen und ermöglichen Ihnen die komfortable Nutzung der Karten-Funktion.
        </p>
        <p>
          (2) Durch den Besuch auf der Website erhält Google die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Zudem werden die unter § 3 dieser Erklärung genannten Daten übermittelt. Dies erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google richten müssen.
        </p>
        <p>
          (3) Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch den Plug-in-Anbieter erhalten Sie in den Datenschutzerklärungen des Anbieters. Dort erhalten Sie auch weitere Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre: <a href="https://www.google.de/intl/de/policies/privacy">https://www.google.de/intl/de/policies/privacy</a>. Google verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich dem EU-US Privacy Shield unterworfen, <a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>          .
        </p>

        <h4 class="text-2xl">Auftragsverarbeiter</h4>
        <p>
          Wir bedienen uns externen Dienstleister (Auftragsverarbeiter) z. B. für Hosting und Betreuung dieser Website, Newsletter oder Zahlungsabwicklungen. Mit dem Dienstleister wurde eine separate Auftragsdatenverarbeitung geschlossen, um den Schutz Ihrer personenbezogenen Daten zu gewährleisten.
        </p>
      </div>
    </perfect-scrollbar>
  </ModalView>

</template>

<script>
import ModalView from "@/components/ModalView.vue";

export default {
  components: {ModalView},
  data() {
    return {
      showHelpModal: false,
      showImpressumModal: false,
      showDatenschutzModal: false,
    }
  }

}
</script>

<style lang="scss" scoped>
.footerstyle {
  border-top: 1px solid white;
}

.border-right {
  border-right:1px solid white;
}

h3, h4, h5 {
  margin-bottom: 0.75rem;
}
p {
  margin-bottom: 1rem;
}


</style>
