<template>
    <div class="flex justify-between">
      <div class="flex w-3/5 flex-col">
        <FilterComponent
            :filter="filter"
            :filteroptions="filteroptions"
            :showyearmonthfilter="true"
            @updateFilter="updateFilter"/>
        <!--            :showstatusfilter="true"-->
      </div>
      <div class="flex w-1/5 justify-end">
        <ImageUpload :currentpath="path" />
        <MydataComponent />
      </div>
    </div>

    <!-- IMAGELIST -->
    <div class=" pt-5" v-if="gimg === null">

      <div v-for="(gitem, idx) in imagegroups" v-bind:key="idx" class="mb-4">
        <div class="font-medium mb-2 border-b-2 border-wuh-green text-wuh-green">
          {{formatYearMonth(gitem.yearMonth)}}
        </div>
        <div>
          <masonry-wall :items="gitem.items" :min-columns="5" :column-width="['250','200','350','250','200']" :gap="16">
            <template #default="{ item, index }">
              <div class="relative"
                   :data-itemid="index"
                   @click="setactiveimage(item)"
                   @dblclick="setfullscreen(item, index)"
                   @mouseover="hoveredIndex = item.fileid"
                   @mouseleave="hoveredIndex = -1">

                <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4 mx-auto"
                     v-if="!item.imgSrc"></div>
                <img :src="item.imgSrc" style="width:100%;max-width:inherit !important;"
                     v-if="item.imgSrc" />
                <div v-show="hoveredIndex === item.fileid"
                     class="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 text-white text-center py-4 hover:bg-opacity-100 transition duration-300 ease-in-out">
                  {{decodeURIComponent(item.title)}}
                </div>
              </div>
            </template>
          </masonry-wall>
        </div>

      </div>

    </div>

  <ImageSlideshow :current-image="gimg"
                    :images="images"
                    @close="disableSlideshow"
                    @next="nextSliderImage"
                    @prev="prevSliderImage"
    />


  <ModalView :show="modalNoFiles" @close="modalNoFiles = false" >
    <h1>Fehler</h1>
    <p>Es konnten keine Bilder gefunden werden.</p>
  </ModalView>

</template>

<script>
import axios from "axios";
import store from "@/store";
import router from "@/router";
import ImageSlideshow from "@/components/ImageSlideshow.vue";
import {mapActions} from "vuex";
import ModalView from "@/components/ModalView.vue";
import ImageUpload from "@/components/ImageUpload.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import MydataComponent from "@/components/MydataComponent.vue";
import { isValid, parse, format } from 'date-fns';
import { de } from 'date-fns/locale'
import emitter from "@/addons/event-bus";

export default {
  name: 'HomeView',
  data() {
    return {
      hovered: false,
      imgLoaded: {},
      gimg: null,
      hoveredIndex: -1,
      images: [],
      imagegroups: [],
      baseUrl: process.env.VUE_APP_APP_APIURL + '/api/getfile',
      timer: null,
      delay: 200, // delay time in milliseconds
      prevent: false,
      message: '',
      filter: {
        searchtext: '',
        searchtags: []
      },
      filteroptions: {
        tags: [],
        dates: []
      },
      showModal: false,
      showMydata: false,
      modalNoFiles: false,

      // PATH for Image Upload
      path: [],
    }
  },
  components: {
    MydataComponent,
    FilterComponent,
    ImageUpload,
    ImageSlideshow,
    ModalView,
  },
  created: function() {
    console.locallog('HomeView loaded')
    this.$store.commit('singleimage/SET_MULTI_IMAGES', []);
    this.$store.commit('singleimage/SET_SINGLE_IMAGE', null);
    this.loadimages();
    let _this = this
    emitter.on('filesuploaded', uploadfinished => {
      if(uploadfinished) {
        _this.loadimages()
      }
    })


    console.locallog('Emitter Init')
    emitter.on('imageditor_open', () => {
      _this.disableSlideshow()
      console.locallog('Emitter imageditor_open')
    })
  },
  computed: {
  },
  beforeUnmount() {
    emitter.all.clear();
  },

  methods: {
    ...mapActions('files', ['fetchfiles','fetchimage','fetchimages']),

    onImageLoad(imgId) {
      this.$set(this.imgLoaded, imgId, true);
    },
    formatYearMonth(yearMonth) {
      const date = parse(yearMonth, 'yyyy-MM', new Date());
      if(!isValid(date)) {
        return 'Unknown'
      }
      return format(date, 'MMMM yyyy', { locale: de });  // 'MMMM yyyy' format for full month name and year
    },

    imagesOfMonth(year, month) {
      let result = this.images.filter((item) => {
        if(item.FMONTH == month && item.FYEAR == year) {
          return item
        }

        console.locallog('imagesOfMonth', item, month, year)
      })

      console.locallog('imagesOdMonth Result', result)
      return result
    },

    decodeURIComponent(value) {
      return decodeURIComponent(value);
    },
    updateFilter(newValue) {
      this.filter.searchtext = newValue.searchtext
      this.filter.searchtags = newValue.searchtags
      this.filter.status = newValue.status

      this.filter.daterange = ''
      if(newValue.yearfilter > 1 || newValue.monthfilter > 1) {
        if(newValue.yearfilter > 1) {
          this.filter.daterange = this.filter.daterange + newValue.yearfilter + '-'
        } else {
          this.filter.daterange = this.filter.daterange + '%-'
        }
        if(newValue.monthfilter > 1) {
          this.filter.daterange = this.filter.daterange + newValue.monthfilter + '-%'
        } else {
          this.filter.daterange = this.filter.daterange +'%'
        }
      }


      console.locallog('Update Filter',newValue);
      this.loadimages()
    },
    async loadimages() {
      let _this = this
      this.$store.commit('globalstore/SET_LOADING', true)
      // await axios.post(process.env.VUE_APP_APP_APIURL + '/api/gethomeimages', {'filter': this.filter})
      await axios.post(process.env.VUE_APP_APP_APIURL + '/api/files/gethome', {'filter': this.filter})
      .then(response => {
        if( response.data.status == 'error'  && response.data.etype == 'tokenexpire') {
          console.locallog('Token expired')
          store.state.auth.token = false
          router.push({ name: 'login' });
          return
        }

        if( !response.data.data.imagecount) {
          this.modalNoFiles = true
          this.images = []
          this.imagegroups = []
          return
        }
        this.images = response.data.data.images

        let groupedFilesList = this.images.reduce((grouped, item) => {
          let date = new Date(item.filedate);
          let yearMonthKey = `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, '0')}`;
          if (!grouped[yearMonthKey]) {
            grouped[yearMonthKey] = [];
          }
          grouped[yearMonthKey].push(item);
          return grouped;
        }, {});

        let groupedFilesArr = Object.keys(groupedFilesList).map(yearMonthKey => ({
          yearMonth: yearMonthKey,
          items: groupedFilesList[yearMonthKey]
        }));

        groupedFilesArr.sort((a, b) => {
          return a.yearMonth > b.yearMonth ? -1 : (a.yearMonth < b.yearMonth ? 1 : 0);
        });
        console.locallog('GroupedFiles', groupedFilesArr)
        _this.fetchimages(this.images, 'thumb')
        this.imagegroups = groupedFilesArr
        this.filteroptions = response.data.data.meta
      })
      .then(() =>{
        this.$store.commit('globalstore/SET_LOADING', false)
      })
      .catch(error => {
        console.locallog('error', error)
        this.$store.commit('globalstore/SET_LOADING', false)
      })
    },

    setactiveimage(item) {
      this.timer = setTimeout(() => {
        if (!this.prevent) {
          console.locallog('single click detected');
          if (!this.$store) {
            console.error("Vuex store is not defined.");
            return;
          }
          try {
            this.$store.commit('singleimage/SET_SINGLE_IMAGE', item);
          } catch (error) {
            console.error("Error committing Vuex mutation:", error);
          }
        }
        this.prevent = false;
      }, this.delay);
    },
    setfullscreen(item, index) {
      clearTimeout(this.timer);
      this.prevent = true;
      console.locallog('double click detected')
      this.$store.state.singleimage.photo = false
      index = this.images.findIndex((i) => i.fileid === item.fileid)
      this.activeindex = index
      this.gimg = item
    },
    nextSliderImage() {
      console.locallog('NextSliderImage', this.activeindex, (this.images.length)-1)
      if(this.activeindex < (this.images.length)-1) {
        this.activeindex++
        this.gimg = this.images[this.activeindex]
      }
    },
    prevSliderImage() {
      console.locallog('PrevSliderImage', this.activeindex, (this.images.length)-1)
      if(this.activeindex > 0) {
        this.activeindex--
        this.gimg = this.images[this.activeindex]
      }
    },
    disableSlideshow() {
      this.gimg = null
      this.activeindex = null
    }
  },
  watch: {
    gimg: function(e) {
      console.locallog('Gimage changed', e, this.gimg, typeof this.gimg)
      if(this.gimg != null) {
        this.fetchimage(this.gimg)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.loader {
  animation: spinner 1.2s infinite linear;
  position: relative;
  top: 50%;
  border-color: transparent;
  @apply border-t-wuh-green;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
