import axios from "axios";

const state = {
    files: false,
    currentpath: [],
    filters: []
};
const mutations = {

};
const actions = {
    fetchall({commit}) {
        console.locallog('Fetch All in Path', commit, state.currentpath)
    },
    async fetchfiles({commit}) {
        console.locallog('Fetch Files in Path', commit, state.currentpath)
    },
    async fetchfolders({commit}) {
        console.locallog('Fetch Folders in Path', commit, state.currentpath)
    },
    async fetchimage(_, item) {
        // console.locallog('Fetch Images in Path', commit, state.currentpath)
        await axios.get(process.env.VUE_APP_APP_APIURL + `/api/getfile/${item.fileid}`, {
            responseType: 'blob', // Important
        })
        .then(response => {
            item.orgimgSrc = URL.createObjectURL(new Blob([response.data]));
        })
        .catch(error => {
            console.error(error);
        })
    },
    async fetchimages(_, images, type = 'thumb') {
        // console.locallog('Fetch Images in Path', commit, state.currentpath)

        for (let item of images) {
            try {

                if(item.thumbnail != '') {
                    console.locallog('Fetch / Set imgSrc', item)
                    item.imgSrc = item.thumbnail
                } else {
                    const response = await axios.get(`${process.env.VUE_APP_APP_APIURL}/api/getfile/${item.fileid}/${type}`, {
                        responseType: 'blob',
                    });
                    item.imgSrc = URL.createObjectURL(new Blob([response.data]));
                }

            } catch (error) {
                console.error(error);
            }
        }
        // console.locallog('Fetch Images in Path END', commit, state.currentpath)
    },
    testfetch({commit}) {
        console.locallog('Fetch Files in Path', commit, state.currentpath)
    },
    setCurrentPath({commit}, path) {
        state.currentpath = path
        console.locallog('Set CurrentPath to', commit, state.currentpath)
    },
    async download({commit}, fileids = false) {

        console.locallog('Initiate Download',commit, fileids)

        await axios.post(process.env.VUE_APP_APP_APIURL + '/api/files/downloadbyid',
            {
                fileids: fileids
            },
            {
                responseType: 'blob',
            }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            let contentDisposition = response.headers['content-disposition'];
            let contentType = response.headers['content-type'];
            let fileExtension = contentType.split('/')[1];
            // const fileName = `${fileId}.${fileExtension}`;
            let fileName = `Files_.${fileExtension}`;

            console.locallog('Disposition', contentDisposition)
            if (contentDisposition) {
                let filenameToken = "filename=";
                let startIdx = contentDisposition.indexOf(filenameToken);
                if (startIdx !== -1) {
                    let endIdx = contentDisposition.indexOf(";", startIdx);
                    if (endIdx === -1) { endIdx = undefined; } // If no semicolon exists, the filename extends to the end of the string
                    fileName = contentDisposition.slice(startIdx + filenameToken.length, endIdx);
                }
            }
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
    },
    filesmoveImage(_commit, item) {
        console.locallog('moveImage ', item, item.title)
    },
    async filesremoveImage(_commit, item) {
        console.locallog('removeImage ', item, item.title)
        await axios.post(process.env.VUE_APP_APP_APIURL + '/api/files/removeimage',
            {
                fileids: item.fileid
            }).then((response) => {
                return response.data.status == 'success'
        })
    },
    filesmoveFolder(_commit, item) {
        console.locallog('moveFolder ', item, item.title)

    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
