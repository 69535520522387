<template>
  <div id="app" class="login">
    <div v-if="$store.state.globalstore.loading" class="fixed inset-0 flex items-center justify-center z-[5000]" style="background-color: rgba(0,0,0,0.5);">
      <div class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-wuh-green"></div>
    </div>
    <RouterView />
    <SiteFooter />
  </div>

  <vue3-toastify />
</template>

<script>
import SiteFooter from "@/components/SiteFooter.vue";

export default {
  components: {SiteFooter},
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
