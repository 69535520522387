<template>

<!--  <ImageEditor :current-image="$store.state.singleimage.photo" v-if="editimage" @close="editimage = false"></ImageEditor>-->
  <ImageEditor :current-image="$store.state.singleimage.photo" v-if="$store.state.singleimage.editimage" @close="$store.state.singleimage.editimage = false"></ImageEditor>

<!--  <perfect-scrollbar v-if="$store.state.singleimage.photo || $store.state.singleimage.photos.length" -->
<!--                     class="flex flex-col w-[18rem] min-w-[18rem] max-w-[18rem] pt-10 px-5 bg-wuh-secondary max-h-screen">-->
  <perfect-scrollbar v-if="$store.state.singleimage.photo || $store.state.singleimage.photos.length"
                     class="flex flex-col w-[25vw] min-w-[25vw] max-w-[25vw] pt-10 px-5 bg-wuh-secondary max-h-screen">
    <a href="#" @click.prevent="$emit('close-image-details')" class="flex self-end w-10 absolute " style="top:-0px; right:-0px;"><img src="@/assets/icons/close.svg"></a>

      <div >
        <ul class="flex  justify-between " :class="{'space-x-4': $store.state.singleimage.photos.length <= 1}">
          <template v-if="$store.state.singleimage.photo.isfolder != 1">
            <li class="border-b-2  pb-4 w-1/4 " :class="{ 'border-gray-600': tabs.general }" @click="setTabActive('general')">
              <img src="@/assets/icons/information.svg" class="w-10 mx-auto" />
            </li>
          </template>

          <template v-if="$store.state.singleimage.photos.length <= 1 && $store.state.singleimage.photo?.isfolder != 1">

            <li class="border-b-2  pb-4 w-1/4" :class="{ 'border-gray-600': tabs.edit }" @click="setTabActive('edit')" v-if="$store.state.singleimage.photos.length <= 1">
              <img src="@/assets/icons/edit.svg" class="w-10 mx-auto" />
            </li>
          </template>
            <li class="border-b-2  pb-4 w-1/4" :class="{ 'border-gray-600': tabs.comment }" @click="setTabActive('comment')" v-if="$store.state.singleimage.photos.length <= 1 && $store.state.auth.token">
              <img src="@/assets/icons/comment.svg" class="w-10 mx-auto" />
            </li>


          <li class="border-b-2  pb-4 w-1/4" :class="{ 'border-gray-600': tabs.share }" @click="setTabActive('share')" v-if="$store.state.currentuser.user.role == 'admin' || $store.state.currentuser.user.role == 'redakteur'">
            <img src="@/assets/icons/share.svg" class="w-10 mx-auto" />
          </li>
        </ul>
      </div>

    <template v-if="$store.state.singleimage.photos.length > 1">
      <h2 class="w-full mt-5 font-extrabold text-wuh-green">Mehrfachbearbeitung</h2>
    </template>

      <div class="flex flex-col" v-if="tabs.general">
        <template  v-if="$store.state.singleimage.photos.length <= 1">
          <h2 class="w-full mt-5 font-extrabold text-wuh-green">Allgemein</h2>
          <div class="flex flex-row mt-5">
            <div class="flex flex-col w-full">
              <label for="name" class="metainputlabel">Titel</label>
              <input id="name" class="metainputfield" type="text"  placeholder="Titel" v-model="decodedTitle" :readonly="readonly"/>
<!--              <input id="name" class="metainputfield" type="text"  placeholder="Titel" v-model="$store.state.singleimage.photo.title" :readonly="readonly"/>-->
            </div>
          </div>

          <template v-if="$store.state.singleimage.photo.isfolder == 0">


            <div class="flex flex-row mt-5">
              <div class="flex flex-col w-full">
                <label for="filesize" class="metainputlabel">Bildgröße</label>
                <input id="filesize" class="metainputfield" type="text" readonly placeholder="Dateigröße" :value="filesize" />
              </div>
            </div>
            <div class="flex flex-row mt-5">
              <div class="flex flex-col w-full">
                <label for="description" class="metainputlabel">Bildbeschreibung</label>
                <textarea id="description" class="metainputfield h-auto" type="text"  placeholder="Beschreibung" v-model="$store.state.singleimage.photo.description" :readonly="readonly">
                </textarea>
              </div>
            </div>
            <div class="flex flex-row mt-5">
              <div class="flex flex-col w-full">
                <label for="subtitle" class="metainputlabel">Bildunterschrift</label>
                <textarea id="subtitle" class="metainputfield  h-auto" type="text"  placeholder="Bildunterschrift" v-model="$store.state.singleimage.photo.subtitle" :readonly="readonly"></textarea>
              </div>
            </div>
            <div class="flex flex-row mt-5">
              <div class="flex flex-col w-full">
                <label for="alttext" class="metainputlabel">Alternativtext</label>
                <textarea id="alttext" class="metainputfield  h-auto" type="text"  placeholder="Alternativtext" v-model="$store.state.singleimage.photo.alttext" :readonly="readonly"></textarea>
              </div>
            </div>

          </template>
        </template>



        <template v-if="$store.state.singleimage.photo.isfolder == 0">
          <h2 class="w-full mt-5 font-extrabold text-wuh-green">Bildeigenschaften </h2>
  <!--        ({{$store.state.singleimage.photo.fileid}})-->
          <div class="flex flex-col mt-5">
            <div class="flex flex-col w-full" :class="{'empty': ($store.state.singleimage.photo.keywords == [] || $store.state.singleimage.photo.keywords == '' || $store.state.singleimage.photo.keywords == null) && !isExternalRoute }" >
              <label for="keywords" class="metainputlabel">Keywords *</label>
              <v-select class="metainputfield" multiple v-model="$store.state.singleimage.photo.keywords"
                        taggable
                        :options="filteroptions.tags"
                        @search="fetchTagOptions"
                        :disabled="readonly"
                        />
            </div>
  <!--          <div class="flex flex-row w-full text-xs pt-0.5 pb-0.5" v-if="$store.state.singleimage.photos.length > 1">-->
  <!--            <input type="checkbox" v-model="extendexistingtags"> <span class="ml-1">Bestehende Tags erweitern</span>-->
  <!--          </div>-->
          </div>
          <div class="flex flex-row mt-5">
            <div class="flex flex-col w-full">
              <label for="filedate" class="metainputlabel">Datum *</label>
              <VueDatePicker class="metainputfield" v-model="$store.state.singleimage.photo.filedate"
                             locale="de-DE"
                             format="dd.MM.yyyy"
                             model-type="yyyy-MM-dd"
                             :enable-time-picker="false"
                             cancelText="Abbrechen"
                             selectText="Auswählen"
                             :readonly="readonly"
                             :class="{'empty': $store.state.singleimage.photo.filedate == ''}"/>
            </div>
          </div>
          <div class="flex flex-row mt-5">
            <div class="flex flex-col w-full">
              <label for="place" class="metainputlabel">Ort *</label>
              <input id="place" class="metainputfield" type="text"  placeholder="Ort" v-model="$store.state.singleimage.photo.place" :readonly="readonly" :class="{'empty': $store.state.singleimage.photo.place == '' || $store.state.singleimage.photo.place == null}"/>
            </div>
          </div>

          <MapComponent :lat="$store.state.singleimage.photo.lat" :lng="$store.state.singleimage.photo.lng">

          </MapComponent>

          <div class="flex flex-row mt-5">
            <div class="flex flex-col w-full">
              <label for="datashown" class="metainputlabel">Abgebildete Person / Gegenstände / Tiere / Pflanzen *</label>
              <input id="datashown" class="metainputfield" type="text"  placeholder="Abbildung" v-model="$store.state.singleimage.photo.datashown" :readonly="readonly" :class="{'empty': $store.state.singleimage.photo.datashown == '' || $store.state.singleimage.photo.datashown == null}"/>
            </div>
          </div>
          <div class="flex flex-row mt-5">
            <div class="flex flex-col w-full">
              <label for="responsible" class="metainputlabel">Urheber/in *</label>
              <input id="responsible" class="metainputfield" type="text"  placeholder="Urheber/in" v-model="$store.state.singleimage.photo.responsible" :readonly="readonly" :class="{'empty': $store.state.singleimage.photo.responsible == '' || $store.state.singleimage.photo.responsible == null}"/>
            </div>
          </div>
          <div class="flex flex-row mt-5">
            <div class="flex flex-col w-full">
              <label for="owner" class="metainputlabel">Rechteinhaber/in *</label>
              <input id="owner" class="metainputfield" type="text" placeholder="Rechteinhaber/in" :value="$store.state.singleimage.photo.rightowner" />
            </div>
          </div>
          <div class="flex flex-row mt-5">
            <div class="flex flex-col w-full">
              <label for="owner" class="metainputlabel">Hochgeladen von *</label>
              <input id="owner" class="metainputfield" type="text" readonly placeholder="Hochgeladen von" :value="$store.state.singleimage.photo.creator" />
            </div>
          </div>
        </template>


        <template v-if="$store.state.singleimage.photo.isfolder == 0">

        <template v-if="!isExternalRoute">

          <div class="flex flex-row mt-5" v-if="$store.state.singleimage.photo.status >= 3 ">
            <div class="flex flex-row w-full">
              <div class="btn-two-grid-parent w-full">
                <div class="btn-two-grid-child"><button class="btn-default w-full" @click.prevent="saveImageMeta">Speichern</button></div>
                <div class="btn-two-grid-child"><button class="btn-default w-full"  @click.prevent="saveImageMetaAndPublish">Veröffentlichen</button></div>
              </div>
            </div>
          </div>

          <div class="flex flex-row mt-5" v-if="($store.state.singleimage.photo.status == 1 || $store.state.singleimage.photo.status == 2) && $store.state.currentuser.user.role == 'admin'">
            <div class="flex flex-row w-full">
              <div class="btn-two-grid-parent w-full">
                <div class="btn-two-grid-child"><button class="btn-default w-full" @click.prevent="saveImageMeta">Speichern</button></div>
              </div>
            </div>
          </div>

          <div class="flex flex-row mt-5" v-if="$store.state.singleimage.photo.status == 2 && $route.path == '/approvals'">
            <div class="flex flex-row w-full">
              <div class="btn-two-grid-parent w-full">
                <div class="btn-two-grid-child"><button class="btn-default w-full" @click.prevent="approveFile">Freigeben</button></div>
                <div class="btn-two-grid-child"><button class="btn-default w-full" @click.prevent="revertapproveFile">Zurückweisen</button></div>
              </div>
            </div>
          </div>



          <div class="flex flex-row text-[10px] font-bold text-center mt-5 pb-10">
            <span><i class="fa fa-info-circle"></i><br />Nach Veröffentlichung können keine Änderungen vorgenommen werden.</span>
          </div>
        </template>

        </template>
      </div>


      <div class="flex flex-1 flex-col" v-if="tabs.edit">
        <h2 class="w-full my-5 font-extrabold text-wuh-green">Bild bearbeiten</h2>

        <button class="btn-default mb-4" @click.prevent="setEditimage()">
          Bearbeitung starten
        </button>
        <p class="text-sm text-wuh-primaryfontcolor">
          Durch die Bearbeitung des Bildes gehen die EXIF Daten verloren!
        </p>
      </div>
      <div class="flex flex-1 flex-col" v-if="tabs.comment">
        <div class="flex">
          <h2 class="flex w-full my-5 font-extrabold text-wuh-green">Kommentare</h2>
        </div>

        <FileComments :item="currentItem"></FileComments>

      </div>
      <div class="flex flex-1 flex-col" v-if="tabs.share">

        <div class="flex flex-col">
          <h2 class="flex flex-row w-full my-5 font-extrabold text-wuh-green">Teilen</h2>

          <div class="flex flex-row w-full ">
              <a href="#" @click.prevent="showexternalshare=true" class="flex items-center">
                <img src="@/assets/icons/share.svg" class="h-10 w-10 "/> <span class="flex-1 ml-2 text-xs">Link teilen</span>
              </a>
          </div>
          <div class="flex flex-row w-full mt-5">
              <a href="#" @click.prevent="showinternalshare=true" class="flex items-center">
                <img src="@/assets/icons/share.svg" class="h-10 w-10 "/> <span class="flex-1 ml-2 text-xs">Internen Link (funktioniert nur für Benutzer, die Zugang zum System haben)</span>
              </a>
          </div>
        </div>

      </div>

    <ModalView :show="showinternalshare" @close="showinternalshare = false" >
      <div class=" sm:w-[50vw] lg:w-[25vw] flex-col">
        <h2 class="font-bold mb-4">Intern Teilen</h2>
        <p class="text-sm mb-4">Wählen Sie einen Benutzer oder eine Gruppe um das ausgewählte Objekt zu teilen.</p>
        <div class="flex flex-col mb-4">
          <v-select class="metainputfield"
                    v-model="sharetype"
                    multiple
                    :options="concatedShareOptions"
                    :reduce="singleitem => singleitem.value"
          >
            <template v-slot:option="option">
              {{option.label}}
              <template v-if="option.value.shareType == 1">
                (Gruppe)
              </template>
              <template v-if="option.value.shareType == 0">
                (Benutzer)
              </template>
            </template>
          </v-select>
        </div>

        <div class="flex flex-col text-right">
          <button class="btn-default !px-1 !inline-block" @click.prevent="addShare">Freigabe erstellen</button>
        </div>



      </div>


    </ModalView>
    <ModalView :show="showexternalshare" @close="showexternalshare = false" >
      <div class=" sm:w-[50vw] lg:w-[25vw] flex-col">
        <h2 class="font-bold mb-4">Externen Link erstellen und versenden</h2>
        <p class="text-sm mb-4">Bitte geben Sie die E-Mail Adresse an, an die ein Link zur externen Datei gesendet werden soll.</p>
        <p class="text-xs mb-4">Mehrere Empfänger können mit ; getrennt angegeben werden</p>
        <div class="flex flex-col mb-4">
          <input type="text" class="maininputfield" name="reciever" v-model="reciever" />
        </div>

        <div class="flex flex-col text-right">
          <button class="btn-default !px-1 !inline-block" @click.prevent="addExternalShare">Freigabelink senden</button>
        </div>



      </div>
    </ModalView>

<!--    ImageDetails: {{showimagedetails}}-->
<!--  </div>-->
  </perfect-scrollbar>
</template>

<script>
import {mapState} from "vuex";
import axios from "axios";
import ImageEditor from "@/components/ImageEditor.vue";
import MapComponent from "@/components/MapComponent.vue";
import FileComments from "@/components/FileComments.vue";
import ModalView from "@/components/ModalView.vue";
import { toast } from "vue3-toastify";


export default {
  name: 'ImageDetails',
  components: {ModalView, FileComments, MapComponent, ImageEditor},
  // props: ['showimagedetails'],
  emits:["close-image-details"],
  data() {
    return {
      img: null,
      tabs: {
        general: true,
        edit: false,
        comment: false,
        share: false,
      },
      filteroptions: {
        tags: []
      },
      editimage: false,
      comment: '',
      extendexistingtags: false,
      readonlymode: false,
      showinternalshare: false,
      showexternalshare: false,
      shareoptions: {},
      sharetype: null,
      concatedShareOptions: [],
      reciever: ''
    }
  },
  computed: {
    ...mapState({
      computedShareoptions() {
        return this.shareoptions.groups.concat(this.shareoptions.users)
      },
      filename() {
        return decodeURIComponent(this.$store.state.singleimage.photo.filename);
      },
      filesize() {
        return (this.$store.state.singleimage.photo.size / 1024 / 1024).toFixed(2) + ' MB'
      },
      currentItem() {
        return this.$store.state.singleimage.photo
      },
      readonly() {
        if(!this.$store.state.auth.token) {
          return true;
        }

        if(this.$store.state.currentuser.user.role == 'admin') {
          return false
        }

        if(this.$route.path == '/approvals') {
          console.locallog('Change', this.$route.path, 'Approval', 'Set Readonly!')
          return true
        }
        return false
      }
    }),
    isExternalRoute() {
      console.locallog('external route?', this.$route.path, !this.$route.path.startsWith('/external'))
      // Hier prüfen wir, ob der aktuelle Route-Pfad nicht mit '/external/zufälligerstring' beginnt
      return this.$route.path.startsWith('/external');
    },
    decodedTitle: {
      get() {
        return decodeURIComponent(this.$store.state.singleimage.photo.title);
      },
      set(value) {
        this.$store.state.singleimage.photo.title = value;
      }
    }
  },
  created() {
    this.loadTags()
    console.locallog('Sidebar created 1')
  },
  mounted() {
    this.loadTags()
    console.locallog('Sidebar mounted 2')
  },
  methods: {

    async addExternalShare() {
      let data = {
        fileid: this.$store.state.singleimage.photos,
        sharedata: ''
      }
      if(this.$store.state.singleimage.photos.length <= 0) {
        data.fileid[0] = this.$store.state.singleimage.photo
      }

      data.reciever = this.reciever
      this.$store.commit('globalstore/SET_LOADING', true)
      await axios.post(process.env.VUE_APP_APP_APIURL + '/api/share/shareexternal', data)
          .then((response) => {
            this.sharetype = null
            console.locallog('resposne', response)
            if(response.data.status == 'success') {
              // Toast INFO
              this.showexternalshare = false
              toast.success('Erfolgreich geteilt')
            } else {
              // tost info ERROR
              toast.error('Problem beim Teilen "'+response.data.data.msg+'"')
            }


          })
          .then(() =>{
            this.$store.commit('globalstore/SET_LOADING', false)
          })
          .catch(error => {
            console.locallog('ERROR', error)
            this.$store.commit('globalstore/SET_LOADING', false)
          })

    },

    async addShare() {
      let data = {
        fileid: this.$store.state.singleimage.photos,
        sharedata: this.sharetype
      }

      if(this.$store.state.singleimage.photos.length <= 0) {
        data.fileid[0] = this.$store.state.singleimage.photo
      }

      console.locallog('DataToShare', data)


      this.$store.commit('globalstore/SET_LOADING', true)
      await axios.post(process.env.VUE_APP_APP_APIURL + '/api/share/sharewith', data)
        .then((response) => {
          this.sharetype = null
          console.locallog('resposne', response)
          if(response.data.status == 'success') {
            // Toast INFO
            this.showinternalshare = false
            toast.success('Erfolgreich geteilt')
          } else {
            // tost info ERROR
            toast.error('Problem beim Teilen "'+response.data.data.msg+'"')
          }


        })
        .then(() =>{
          this.$store.commit('globalstore/SET_LOADING', false)
        })
        .catch(error => {
          console.locallog('ERROR', error)
          this.$store.commit('globalstore/SET_LOADING', false)
        })


    },
    async fetchTagOptions(search, loading) {
      loading(true);  // set loading state
      // this.options = await axios.get('/api/your-tags-endpoint'); // replace /api/your-tags-endpoint with your API endpoint
      await axios.post(process.env.VUE_APP_APP_APIURL + '/api/files/gettags', {
        'filter': search
      })
        .then(response => {
          console.locallog('Response', response.data.data.tags)
          this.filteroptions.tags = response.data.data.tags
        })
        .then(() =>{
          // this.$store.commit('globalstore/SET_LOADING', false)
        })
        .catch(error => {
          console.locallog('ERROR', error)
          // this.$store.commit('globalstore/SET_LOADING', false)
        })
      // this.filteroptions.tags = ['L1','L2','L3']

      loading(false); // unset loading state
    },
    async loadTags() {
      await axios.post(process.env.VUE_APP_APP_APIURL + '/api/get/tags', {})
        .then(response => {
          console.locallog('Response', response.data.data.tags)
          console.locallog('Sidebar Tags', response.data.data.tags)
          this.filteroptions.tags = response.data.data.tags
        })
        .catch(error => {
          console.locallog('ERROR', error)
        })

    },
    setEditimage() {
      this.editimage = true
      this.$store.state.singleimage.editimage = true
    },

    setTabActive: function(tab) {
      for (let key in this.tabs) {
        this.tabs[key] = false;
      }
      // Set the selected tab to true
      this.tabs[tab] = true;

      // toast.success('Erfolgreich geteilt')
    },
    closesidebar: function() {
      // this.$store.state.singleimage.photo = false
      this.$store.commit('singleimage/SET_SINGLE_IMAGE', false)
      console.locallog('Close', this.$store.state.singleimage.photo)
    },

    async saveImageMetaAndPublish() {
      let _this = this
      this.$store.commit('globalstore/SET_LOADING', true)

      if(this.$store.state.singleimage.photos.length > 1) {
        await this.$store.state.singleimage.photos.forEach(function(item, index) {
          console.locallog('itemToSave', item, index)
          // Overwrite item data

          if(_this.extendexistingtags) {
            item.keywords = [...item.keywords, ..._this.$store.state.singleimage.photo.keywords]
          } else {
            item.keywords = _this.$store.state.singleimage.photo.keywords
          }

          item.filedate = _this.$store.state.singleimage.photo.filedate
          item.place = _this.$store.state.singleimage.photo.place
          item.datashown = _this.$store.state.singleimage.photo.datashown
          item.responsible = _this.$store.state.singleimage.photo.responsible
          item.status = 2

          axios.post(process.env.VUE_APP_APP_APIURL + '/api/saveimagemeta', item)
            .then(response => {
              console.locallog(response.data, item);
              _this.$store.commit('globalstore/SET_LOADING', false)
            })
            .catch(error => {
              console.locallog('error', error, item)
              _this.$store.commit('globalstore/SET_LOADING', false)
            })
          console.locallog('Item to Save', item)
        })

        this.$store.commit('globalstore/SET_LOADING', false)

      } else {

        let image = this.$store.state.singleimage.photo
        image.status = 2
        await axios.post(process.env.VUE_APP_APP_APIURL + '/api/saveimagemeta', image)
            .then(response => {
              console.locallog(response.data, image);
              this.$store.commit('globalstore/SET_LOADING', false)
            })
            .catch(error => {
              console.locallog('error', error, image)
              this.$store.commit('globalstore/SET_LOADING', false)
            })
      }

    },


    async saveImageMeta() {
      let _this = this
      this.$store.commit('globalstore/SET_LOADING', true)

      if(this.$store.state.singleimage.photos.length > 1) {
        console.locallog('Images to save', this.$store.state.singleimage.photos)
        await this.$store.state.singleimage.photos.forEach(function(item, index) {

          if(_this.extendexistingtags) {
            item.keywords = [...item.keywords, ..._this.$store.state.singleimage.photo.keywords]
          } else {
            item.keywords = _this.$store.state.singleimage.photo.keywords
          }

          item.filedate = _this.$store.state.singleimage.photo.filedate
          item.place = _this.$store.state.singleimage.photo.place
          item.datashown = _this.$store.state.singleimage.photo.datashown
          item.responsible = _this.$store.state.singleimage.photo.responsible


          axios.post(process.env.VUE_APP_APP_APIURL + '/api/saveimagemeta', item)
          .then(response => {
            console.locallog(response.data, item);
          })
          .catch(error => {
            console.locallog('error', error, item)
          })

          console.locallog('Item to Save', item, index)
        })

        this.$store.commit('globalstore/SET_LOADING', false)


      } else {

        let image = this.$store.state.singleimage.photo
        await axios.post(process.env.VUE_APP_APP_APIURL + '/api/saveimagemeta', image)
            .then(response => {
              console.locallog(response.data, image);
              this.$store.commit('globalstore/SET_LOADING', false)
            })
            .catch(error => {
              console.locallog('error', error, image)
              this.$store.commit('globalstore/SET_LOADING', false)
            })
      }



    },

    async approveFile() {
      let image = this.$store.state.singleimage.photo
      image.status = 1
      await axios.post(process.env.VUE_APP_APP_APIURL + '/api/files/approve', image)
          .then(response => {
            console.locallog(response.data, image);
            this.$store.commit('globalstore/SET_LOADING', false)
          })
          .catch(error => {
            console.locallog('error', error, image)
            this.$store.commit('globalstore/SET_LOADING', false)
          })
    },

    async revertapproveFile() {
      this.$store.commit('globalstore/SET_LOADING', true)
      let image = this.$store.state.singleimage.photo
      image.status = 3
      await axios.post(process.env.VUE_APP_APP_APIURL + '/api/files/revertapprove', image)
          .then(response => {
            console.locallog(response.data, image);
            this.$store.commit('globalstore/SET_LOADING', false)
          })
          .catch(error => {
            console.locallog('error', error, image)
            this.$store.commit('globalstore/SET_LOADING', false)
          })
    },

    async getshareoptions() {

      this.$store.commit('globalstore/SET_LOADING', true)

      // Check if single or multi
      let data = {
        fileids: [this.$store.state.singleimage.photo]
      }

      if(this.$store.state.singleimage.photos.length >= 2) {
        data = {
          fileids: this.$store.state.singleimage.photos
        }
      }
      // let image = this.$store.state.singleimage.photo
      // let data = {fileids: this.$store.state.singleimage.photos}
      await axios.post(process.env.VUE_APP_APP_APIURL + '/api/share/getshareoptions', data)
          .then(response => {
            console.locallog('ShareOptions ', response.data);


            this.shareoptions = {
              groups: response.data.data.groups,
              users: response.data.data.users
            }

            this.concatedShareOptions = this.shareoptions.groups.concat(this.shareoptions.users)

                this.$store.commit('globalstore/SET_LOADING', false)
          })
          .catch(error => {
            console.locallog('error', error, data)
            this.$store.commit('globalstore/SET_LOADING', false)
          })

    }


  },
  watch: {
    showinternalshare: function() {
      if(this.showinternalshare) {
        this.getshareoptions()
      }
    },
    '$store.state.singleimage.photo': function() {
      console.locallog('PhotoChanged', this.$store.state.singleimage.photo)
      this.setTabActive('general')

      if(this.$store.state.singleimage.photo && this.$store.state.singleimage.photo.isfolder == 1) {
        this.tabs.general = false
        this.tabs.comment = true
      }


      if(this.$route.path == '/approvals') {
        console.locallog('Change', this.$route.path, 'Approval', 'Set Readonly!')
        this.readonlymode = true
      } else {
        this.readonlymode = false
      }
    },
    '$store.state.singleimage.photos': function() {
      console.locallog('PhotosChanged', this.$store.state.singleimage.photos)
      this.setTabActive('general')
    },
  }
}
</script>

<style lang="scss" >

.metainputlabel {
  @apply text-[12px] font-bold text-wuh-primaryfontcolor;
}

/* For native select */
textarea.metainputfield,
input.metainputfield,
select.metainputfield {
  @apply flex-1 bg-wuh-lightgreen text-sm text-wuh-primaryfontcolor rounded mr-3 px-4;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid transparent;
  border-left: none;
  outline: none;
  margin: 4px 0 0;
  padding: 0 7px;
  max-width: 100%;
  flex-grow: 1;
  z-index: 1;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 26px;
}


/* For vue-select */
.metainputfield.vs--searchable {
  margin-top:4px;
  height: 29px;
  z-index: 50;
}

.metainputfield.v-select,
.metainputfield.vs--searchable,
.metainputfield .vs__search::placeholder,
.metainputfield .vs__dropdown-toggle {
  height:auto;
  min-height:29px;
}

.metainputfield .vs__search::placeholder,
.metainputfield .vs__dropdown-toggle,
.metainputfield .vs__dropdown-menu {
  @apply flex-1 bg-wuh-lightgreen text-sm text-wuh-primaryfontcolor rounded mr-3 px-4;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid transparent;
  border-left: none;
  outline: none;
  margin: 0 0;
  padding: 0 0;
  max-width: 100%;
  flex-grow: 1;
  z-index: 1;
  //padding-top: 5px;
  //padding-bottom: 5px;
}




.metainputfield .vs__selected {
  line-height: 0px;
  height: 20px;
  margin-top: 3px;
  margin-right:3px;
}


/* For VueDatePicker */
.metainputfield.dp__main {
  padding:0;
  margin:0;
}

.metainputfield .dp__input {
  @apply flex-1 bg-wuh-lightgreen text-sm text-wuh-primaryfontcolor rounded mr-3;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid transparent;
  border-left: none;
  outline: none;
  max-width: 100%;
  flex-grow: 1;
  z-index: 1;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 29px;
}

textarea.metainputfield[readonly],
input.metainputfield[readonly],
.metainputfield  input[readonly],
select.metainputfield[readonly],
.metainputfield.vs--disabled .vs__search,
.metainputfield.vs--disabled .vs__dropdown-toggle,
.metainputfield.vs--disabled .vs__actions,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__clear,
.vs--disabled .vs__search,
.vs--disabled .vs__selected,
.vs--disabled .vs__open-indicator {
  @apply bg-neutral-300 ;
}

.dp__action_button.dp__action_cancel {
  @apply bg-wuh-secondary rounded text-wuh-primaryfontcolor  px-1 py-0.5;
}

.dp__action_button.dp__action_select {
  @apply bg-wuh-green rounded text-white  px-1 py-0.5;
}
.dp__action_button.dp__action_cancel:hover {
  @apply bg-wuh-green-focus rounded text-white  px-1 py-0.5;
}

.dp__action_button.dp__action_select:hover {
  @apply bg-wuh-green-focus rounded text-white  px-1 py-0.5;
}

input.empty,
select.empty,
.empty .metainputfield .vs__search::placeholder,
.empty .metainputfield .vs__dropdown-toggle,
.empty .metainputfield .vs__dropdown-menu
{
  @apply bg-wuh-red;
}


</style>
