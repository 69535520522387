<template>
    <component :is="layout">
      <RouterView/>
    </component>

  <vue3-toastify />
</template>

<style lang="scss">

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(127, 141, 4, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 10px;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.btn {
  @apply rounded bg-wuh-btn-green text-white font-bold px-2 py-0.5;
}

</style>
<script>
import DefaultLayout from "./layouts/DefaultLayout.vue";
import LoginLayout from "./layouts/LoginLayout.vue";
import ExternalLayout from "./layouts/ExternalLayout.vue";
import 'vue3-toastify/dist/index.css';
// const defaultLayout = 'default'
export default {
  components: {
    DefaultLayout,
    LoginLayout,
    ExternalLayout
  },
  computed: {
    layout () {
      console.log('Layout', this.$route.meta.layout)
      return (this.$route.meta.layout || 'Default') + '-layout'
    }
  },
  mounted () {
    localStorage.name = 'ben'
  }
}

</script>
