const state = {
    photo: false,
    photos: [],
    editoropen: false,
    editimage: false
};
const mutations = {
    SET_SINGLE_IMAGE(state, item) {
        console.locallog('SET_SINGLE_IMAGE', item)
        state.photo = item
    },
    SET_MULTI_IMAGES(state, list) {
        console.locallog('SET_MULTI_IMAGES', list)
        state.photo = list[0]
        state.photos = list
    }
};

export default {
    namespaced: true,
    state,
    mutations
}
