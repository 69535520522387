<template>
  <a href="#" class="mr-0 ml-1" @click.prevent="showMydata = true">
    <img src="@/assets/icons/mydata.svg" class="w-10" />
  </a>


<!--  <div class="modal-overlay" v-if="show" @click="$emit('close')">-->
  <div class="modal-overlay z-50" v-if="showMydata" @click="showMydata = false">
    <div class="modal relative w-[38vw]" @click.stop>
      <div class="modal-header flex flex-row h-4 items-center ml-2">
        <div class="absolute right-0.5 top-0.5 w-12 h-12">
          <button class="close-button" @click="showMydata = false"><img src="@/assets/icons/close.svg" class="w-12 h-12"></button>
        </div>
<!--        <div class="flex flex-col w-1/2 ">{{ modalTitle }}</div>-->
<!--        <div class="flex flex-col w-1/2 items-center justify-end"><button class="close-button" @click="showMydata = false"><img src="@/assets/icons/close.svg" class="w-8 h-8"></button></div>-->
      </div>
      <div class="modal-body flex flex-row px-4 pb-4">

        <template v-if="overview">

          <div class="flex flex-col w-2/5 items-center text-center pr-3">
            <img :src="avatarsrc" class="w-24 h-24" @error="setDefaultAvatar"/>

            <span v-if="$store.state.currentuser.user.isadmin" class="text-xs pt-0.5">Administrator</span>
            <span v-if="$store.state.currentuser.user.isredakteur" class="text-xs pt-0.5">Redakteur</span>
            <span v-if="!$store.state.currentuser.user.isredakteur && !$store.state.currentuser.user.isadmin" class="text-xs pt-0.5">Benutzer</span><br />

            <button class="btn w-full mb-3" @click="setScreen('uploadvatar')">Bild ändern</button>
            <button class="btn w-full" @click="setScreen('passreset')">Passwort ändern</button>
          </div>
          <div class="flex flex-col w-3/5 pl-3">
            <span class="font-bold text-wuh-primaryfontcolor mb-3">Meine Daten</span>
            <div class="flex flex-col mb-3">
              <label class="inputlabel flex flex-row font-bold mb-0">Name</label>
              <input type="text" class="flex flex-row inputfield " :value="$store.state.currentuser.user.displayname"/>
            </div>
            <div class="flex flex-col">
              <label class="inputlabel flex flex-row font-bold mb-0">E-Mail Adresse</label>
              <input type="text" class="flex flex-row inputfield " :value="$store.state.currentuser.user.email"/>
            </div>
          </div>

        </template>
        <template v-if="passreset">
          <div class="flex flex-col w-full">
            <span class="font-bold text-wuh-primaryfontcolor mb-3">Passwort ändern</span>
            <div class="flex flex-col mb-3">
              <label class="inputlabel flex flex-row font-bold mb-0">Neues Passwort</label>
              <input type="password" class="flex flex-row inputfield " value="password" v-model="password"/>
            </div>
            <div class="flex flex-col mb-3">
              <label class="inputlabel flex flex-row font-bold mb-0">Neues Passwort wiederholen</label>
              <input type="password" class="flex flex-row inputfield " value="password_repeat" v-model="password_repeat"/>
            </div>
<!--            <div class="flex flex-col mb-3">-->
<!--              <small class="warning">Nach dem ändern des Passworts werden sie zum Login umgeleitet und müssen sich mit ihren neuen Daten neu anmelden.</small>-->
<!--            </div>-->
            <div class="flex flex-row text-center">
              <div class="flex flex-col w-1/2 pr-3">
                <button class="btn w-full" @click="setScreen('overview')">Abbrechen</button>
              </div>
              <div class="flex flex-col w-1/2 pl-3">
                <button class="btn w-full" @click="savepassword">Passwort speichern</button>
              </div>
            </div>
          </div>
        </template>

        <template v-if="uploadvatar">
          <div class="flex flex-col w-full">
            <span class="font-bold text-wuh-primaryfontcolor mb-3">Bild hochladen</span>
            <div class="flex flex-col mb-3">
<!--              <label class="inputlabel flex flex-row font-bold mb-0">Avatar auswählen</label>-->
              <input type="file" class="flex flex-row inputfield hidden" @change="onFileChange" name="avatar" ref="fileInput"/>
<!--              <button @click="$refs.fileInput.click()" class="btn ">Bild auswählen</button>-->
            </div>
            <div class="flex flex-row text-center">
              <div class="flex flex-col w-1/2 pr-3">
                <button class="btn w-full" @click="setScreen('overview')">Abbrechen</button>
              </div>
              <div class="flex flex-col w-1/2 pl-3">
                <button @click="$refs.fileInput.click()" class="btn w-full">Bild auswählen</button>
<!--                <button class="btn w-full" @click="uploadavatar()">Hochladen</button>-->
              </div>
            </div>
          </div>
        </template>

      </div>
      <div class="modal-footer text-center" >
        <div v-html="flashmsg"></div>
      </div>


    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import router from "@/router";

export default {

  name: "MydataComponent",
  props: ["show"],
  data() {
    return {
      showMydata: false,
      overview: true,
      passreset: false,
      uploadvatar: false,
      modalTitle: '',
      flashmsg: '',
      password:'',
      password_repeat:'',
      selectedAvatar: null,
      avatarsrc: null
    }
  },
  mounted() {
    console.locallog('Mounted')
    this.fetchavatar()
  },
  methods: {

    setDefaultAvatar(event) {
      event.target.src = require('@/assets/icons/mydata.svg')
    },

    setScreen(screen) {
      this.overview = this.passreset = this.uploadvatar = false
      this[screen]= true
    },
    onFileChange(e) {
      this.selectedFile = e.target.files[0];
      this.uploadavatar()
    },

    async uploadavatar() {
      let formData = new FormData();
      formData.append('image', this.selectedFile);

      axios.post(process.env.VUE_APP_APP_APIURL + '/api/user/setavatarimage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        this.fetchavatar()
        this.setScreen('overview')
        this.$refs.fileInput.type = 'text';
        this.$refs.fileInput.type = 'file';
        console.locallog(response); // handle the response from the server
      }).catch(error => {
        console.error(error); // handle the error
      });
    },

    async savepassword() {
      let _this = this
      this.flashmsg = 'Passwort erfolgreich gespeichert.'

      if(this.password == this.password_repeat && this.isValidPassword(this.password)) {
        let _this = this

        this.$store.commit('globalstore/SET_LOADING', true)
        await axios.post(process.env.VUE_APP_APP_APIURL + '/api/user/setpassword', {
          'password':_this.password
        })
            .then(response => {
              if( response.data.status == 'error' ) {
                if(response.data.etype == 'tokenexpire') {
                  console.locallog('Token expired')
                  store.state.auth.token = false
                  router.push({ name: 'login' });
                  return
                }
              }
              console.locallog(response.data);
              // Set new generated Token
              localStorage.setItem('user-token', response.data.data.token);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.data.token;

              this.$store.commit('globalstore/SET_LOADING', false)
            })
            .catch(error => {
              console.locallog('error', error)
              this.$store.commit('globalstore/SET_LOADING', false)
            })
        setTimeout(function() {
          _this.flashmsg = ''
        }, 5000)
        this.setScreen('overview')
        this.password = ''
        this.password_repeat = ''
      } else {
        if(this.password != this.password_repeat) {
          _this.flashmsg = 'Passwörter müssen übereinstimmen'
        } else {
          _this.flashmsg = 'Passwort nicht komplex genug.<br /><ul><li>min. 8 Zeichen</li><li>min. 1 Sonderzeichen</li><li>min. 1 Ziffer</li></ul>'
        }
      }



    },
    isValidPassword(password) {
      const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
      return regex.test(password);
    },
    async fetchavatar() {
      console.locallog('Fetch Images in Path')
      await axios.get(process.env.VUE_APP_APP_APIURL + `/api/user/getavatar`, {
        responseType: 'blob', // Important
      })
          .then(response => {
            this.avatarsrc = URL.createObjectURL(new Blob([response.data]));
          })
          .catch(error => {
            console.error(error);
          })
    },


  }

}
</script>

<style lang="scss" scoped>
.inputlabel {
  @apply mb-2 font-bold text-wuh-primaryfontcolor;
}
.inputfield {
  @apply flex-1 bg-wuh-lightgreen text-wuh-primaryfontcolor rounded px-4 ;
}
.inputfield.v-select {
  @apply m-0 p-0;
}
</style>
