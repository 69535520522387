<template>
  <div class="modal-overlay" v-if="show" @click="$emit('close')">
    <div class="modal relative" @click.stop>
      <h3>{{title}}</h3>
      <button class="close-button" @click="$emit('close')">X</button>
      <slot></slot>
      <div class="flex items-center">
        <div class="w-1/2 text-left">
          <button @click="handleCancel" class="btn-default !bg-wuh-lightgreen">{{cancelbtn}}</button>
        </div>
        <div class="w-1/2 text-right">
          <button @click="handleSubmit" class="btn-default">{{submitbtn}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConfirmationDialog',
  props: {
    show: {
      type: Boolean
    },
    title: {
      type: String,
      required: true
    },
    submitbtn: {
      type: String,
      required: true
    },
    cancelbtn: {
      type: String,
      required: true
    },
    item: {
      type: [Array, Object],
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', this.item, true)
    },
    handleCancel() {
      this.$emit('cancel', this.item, false)
    }
  }

}
</script>

<style lang="scss" scoped>

</style>
