import { createStore } from 'vuex';
import auth from '@/store/modules/auth'
import singleimage from "@/store/modules/singleimage";
import collection from "@/store/modules/collection";
import currentuser from "@/store/modules/currentuser";
import globalstore from "@/store/modules/globalstore";
import files from "@/store/modules/files";


export default createStore({
  modules: {
    globalstore,
    files,
    auth,
    singleimage,
    collection,
    currentuser
  }
})
