<template>

<!--  v-if="currentcollection"-->
  <perfect-scrollbar v-if="currentcollection" class="flex flex-col w-[25vw] min-w-[25vw] max-w-[25vw] pt-10 px-5 bg-wuh-secondary max-h-screen">
    <a href="#" @click.prevent="$emit('close-collection-details')" class="flex self-end w-10 absolute " style="top:-0px; right:-0px;"><img src="@/assets/icons/close.svg"></a>

    <div >
      <ul class="flex  justify-between " :class="{'space-x-4': $store.state.singleimage.photos.length <= 1}">
        <li class="border-b-2  pb-4 w-1/4 " :class="{ 'border-gray-600': tabs.general }" @click="setTabActive('general')">
          <img src="@/assets/icons/information.svg" class="w-10 mx-auto" />
        </li>

<!--        <template v-if="$store.state.singleimage.photos.length <= 1 && $store.state.singleimage.photo.isfolder != 1">-->
<!--          <li class="border-b-2  pb-4 w-1/4" :class="{ 'border-gray-600': tabs.edit }" @click="setTabActive('edit')" v-if="$store.state.singleimage.photos.length <= 1">-->
<!--            <img src="@/assets/icons/edit.svg" class="w-10 mx-auto" />-->
<!--          </li>-->
<!--        </template>-->
        <li class="border-b-2  pb-4 w-1/4" :class="{ 'border-gray-600': tabs.comment }" @click="setTabActive('comment')" v-if="$store.state.singleimage.photos.length <= 1 && $store.state.auth.token">
          <img src="@/assets/icons/comment.svg" class="w-10 mx-auto" />
        </li>

        <li class="border-b-2  pb-4 w-1/4" :class="{ 'border-gray-600': tabs.share }" @click="setTabActive('share')" v-if="$store.state.currentuser.user.role == 'admin' || $store.state.currentuser.user.role == 'redakteur'">
          <img src="@/assets/icons/share.svg" class="w-10 mx-auto" />
        </li>
      </ul>
    </div>

    <div class="flex flex-col" v-if="tabs.general">
      <h2 class="w-full mt-5 font-extrabold text-wuh-green">Allgemein</h2>
      <div class="flex flex-row mt-5">
        <div class="flex flex-col w-full">
          <label for="name" class="metainputlabel">Titel</label>
          <input id="name" class="metainputfield" type="text"  placeholder="Titel" v-model="$store.state.collection.collection.title" :readonly="readonly"/>
          <!--              <input id="name" class="metainputfield" type="text"  placeholder="Titel" v-model="$store.state.singleimage.photo.title" :readonly="readonly"/>-->
        </div>
      </div>

      <div class="flex flex-row mt-5">
        <div class="flex flex-col w-full">
          <label for="description" class="metainputlabel">Bildbeschreibung</label>
          <textarea id="description" class="metainputfield h-auto" type="text"  placeholder="Beschreibung" v-model="$store.state.collection.collection.description" :readonly="readonly">
          </textarea>
        </div>
      </div>

      <div class="flex flex-row mt-5" v-if="$store.state.collection.collection">
        <div class="flex flex-row w-full">
          <div class="btn-two-grid-parent w-full">
            <div class="btn-two-grid-child"><button class="btn-default w-full" @click.prevent="savecollectioninfo">Speichern</button></div>
          </div>
        </div>
      </div>

    </div>

    <div class="flex flex-col" v-if="tabs.comment">
      <CollectionComments :item="$store.state.collection.collection" />
    </div>

    <div class="flex flex-col" v-if="tabs.share">
      <div class="flex flex-col">
        <h2 class="flex flex-row w-full my-5 font-extrabold text-wuh-green">Teilen</h2>

        <div class="flex flex-row w-full ">
          <a href="#" @click.prevent="showexternalshare=true" class="flex items-center">
            <img src="@/assets/icons/share.svg" class="h-10 w-10 "/> <span class="flex-1 ml-2 text-xs">Link teilen</span>
          </a>
        </div>
        <div class="flex flex-row w-full mt-5">
          <a href="#" @click.prevent="showinternalshare=true" class="flex items-center">
            <img src="@/assets/icons/share.svg" class="h-10 w-10 "/> <span class="flex-1 ml-2 text-xs">Internen Link (funktioniert nur für Benutzer, die Zugang zum System haben)</span>
          </a>
        </div>
      </div>
    </div>

  </perfect-scrollbar>


  <ModalView :show="showinternalshare" @close="showinternalshare = false" >
    <div class=" sm:w-[50vw] lg:w-[25vw] flex-col">
      <h2 class="font-bold mb-4">Intern Teilen</h2>
      <p class="text-sm mb-4">Wählen Sie einen Benutzer oder eine Gruppe um das ausgewählte Objekt zu teilen.</p>
      <div class="flex flex-col mb-4">
        <v-select class="metainputfield"
                  v-model="shareto"
                  multiple
                  :options="sharees"
        >
          <template v-slot:option="option">
            {{option.label}}
            <template v-if="option.value.shareType == 1">
              (Gruppe)
            </template>
            <template v-if="option.value.shareType == 0">
              (Benutzer)
            </template>
          </template>
        </v-select>
      </div>

      <div class="flex flex-col text-right">
        <button class="btn-default !px-1 !inline-block" @click.prevent="addShare">Freigabe erstellen</button>
      </div>
    </div>
  </ModalView>
  <ModalView :show="showexternalshare" @close="showexternalshare = false" >
    <div class=" sm:w-[50vw] lg:w-[25vw] flex-col">
      <h2 class="font-bold mb-4">Externen Link erstellen und versenden</h2>
      <p class="text-sm mb-4">Bitte geben Sie die E-Mail Adresse an, an die ein Link zur Kollektion gesendet werden soll.</p>
      <p class="text-xs mb-4">Mehrere Empfänger können mit ; getrennt angegeben werden</p>
      <div class="flex flex-col mb-4">
        <input type="text" class="maininputfield" name="reciever" v-model="reciever" />
      </div>

      <div class="flex flex-col text-right">
        <button class="btn-default !px-1 !inline-block" @click.prevent="addExternalShare">Freigabelink senden</button>
      </div>
    </div>
  </ModalView>


</template>

<script>
import {mapState} from "vuex";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import { toast } from "vue3-toastify";
import CollectionComments from "@/components/CollectionComments.vue";
import ModalView from "@/components/ModalView.vue";

export default {
  name: 'CollectionDetails',
  components: {ModalView, CollectionComments},
  emits:["close-collection-details"],
  data() {
    return {
      tabs: {
        general: true,
        edit: false,
        comment: false,
        share: false,
      },
      showexternalshare: false,
      showinternalshare: false,
      sharees: [],
      shareto: '',
      reciever: ''
    }
  },
  computed: {
    ...mapState({
      currentcollection() {
        return this.$store.state.collection.collection
      },
      readonly() {
        return false
      },
    })
  },
  mounted() {
    this.getSharees()
  },
  methods: {

    async addExternalShare() {
      let data = {
        collection_id: this.$store.state.collection.collection.id,
        reciever: this.reciever
      }

      this.$store.commit('globalstore/SET_LOADING', true)
      await axios.post(process.env.VUE_APP_APP_APIURL + '/api/collection/addexternalshare', data)
          .then(response => {
            if( response.data.status == 'error' ) {
              if(response.data.etype == 'tokenexpire') {
                store.state.auth.token = false
                router.push({ name: 'login' });
                return
              }
            }
            if(response.data.status == 'success') {
              // toast.success('Erfolgreich gespeichert.')
              this.shareto = ''
              this.reciever = ''

              response.data.data.errors.forEach(sitem => {
                toast.error('Fehler ' + sitem.email + ': ' + sitem.reason);
              })
              response.data.data.success.forEach(sitem => {
                toast.success('Erfolgreich ' + sitem.email + ': ' + sitem.reason);
              })


              console.locallog('Saved success')
            }
            this.$store.commit('globalstore/SET_LOADING', false)
          })
          .catch(error => {
            console.locallog('error', error)
            this.$store.commit('globalstore/SET_LOADING', false)
          })



    },

    async addShare() {

      let data = {
        collection_id: this.$store.state.collection.collection.id,
        shareto: this.shareto
      };

      this.$store.commit('globalstore/SET_LOADING', true)
      await axios.post(process.env.VUE_APP_APP_APIURL + '/api/collection/saveshare', data)
          .then(response => {
            if( response.data.status == 'error' ) {
              if(response.data.etype == 'tokenexpire') {
                console.locallog('Token expired')
                store.state.auth.token = false
                router.push({ name: 'login' });
                return
              }
            }
            if(response.data.status == 'success') {
              toast.success('Erfolgreich gespeichert.')
              this.shareto = ''
              this.reciever = ''
              console.locallog('Saved success')
            }
            this.$store.commit('globalstore/SET_LOADING', false)
          })
          .catch(error => {
            console.locallog('error', error)
            this.$store.commit('globalstore/SET_LOADING', false)
          })


    },

    async getSharees() {
      // getsharees
      this.$store.commit('globalstore/SET_LOADING', true)
      await axios.post(process.env.VUE_APP_APP_APIURL + '/api/collection/getsharees', {})
          .then(response => {
            if( response.data.status == 'error' ) {
              if(response.data.etype == 'tokenexpire') {
                console.locallog('Token expired')
                store.state.auth.token = false
                router.push({ name: 'login' });
                return
              }
            }
            if(response.data.status == 'success') {
              this.sharees = response.data.data.sharees
            }
            this.$store.commit('globalstore/SET_LOADING', false)
          })
          .catch(error => {
            console.locallog('error', error)
            this.$store.commit('globalstore/SET_LOADING', false)
          })

    },
    setTabActive(tab) {
      for (let key in this.tabs) {
        this.tabs[key] = false;
      }
      // Set the selected tab to true
      this.tabs[tab] = true;

      // toast.success('Erfolgreich geteilt')
    },
    async savecollectioninfo() {

      let collection = this.$store.state.collection.collection;

      this.$store.commit('globalstore/SET_LOADING', true)
      await axios.post(process.env.VUE_APP_APP_APIURL + '/api/collections/updatecollection', collection)
          .then(response => {
            if( response.data.status == 'error' ) {
              if(response.data.etype == 'tokenexpire') {
                console.locallog('Token expired')
                store.state.auth.token = false
                router.push({ name: 'login' });
                return
              }
            }
            if(response.data.status == 'success') {
              toast.success('Erfolgreich gespeichert.')
              console.locallog('Saved success')

            }
            this.$store.commit('globalstore/SET_LOADING', false)
          })
          .catch(error => {
            console.locallog('error', error)
            this.$store.commit('globalstore/SET_LOADING', false)
          })

    }

  }
}
</script>

<style lang="scss" scoped>

</style>
