<template>
  <div v-if="lat && lng && lat != '-' && lng != '-'" style="height: 150px; width: 100%;" class="mt-4 z-20">
    <l-map :center="center" :zoom="zoom" :options="{scrollWheelZoom: false}">
      <l-tile-layer :url="url"></l-tile-layer>
      <l-marker :lat-lng="center"></l-marker>
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default {
  name: 'MapComponent',
  props: ['lat', 'lng'],
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 15,
    };
  },
  computed: {
    center() {
      return { lat: this.lat, lng: this.lng };
    },
  },
};
</script>

<style scoped>
.l-map {
  height: 100%;
  width: 100%;
}
</style>
