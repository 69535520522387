const state = {
    collection: false
};
const mutations = {
    SET_COLLECTION(state, result) {
        console.locallog('SET_COLLECTION', result)
        state.collection = result
    },
    UNSET_COLLECTION(state, result) {
        console.locallog('UNSET_COLLECTION', result)
        state.collection = result
    }
};
// const actions = {
//
// };
export default {
    namespaced: true,
    state,
    mutations,
    // actions,
}
