import axios from "axios";
import router from "@/router";

const state = {
    user: {
        role: null
    }
};


const mutations = {
    SET_USER(state, user) {
        state.user = user;
    },
};

const actions = {
    async getuser({ commit }, token) {
        console.locallog('Refresh USER!', token);
        if(token) {
            const response = await axios.post(process.env.VUE_APP_APP_APIURL + '/api/getuser', {
            });
            if(response.data.status == 'success') {
                if(response.data.data.groups.element.includes('admin')) {
                    response.data.data.role = 'admin'
                } else if(response.data.data.groups.element.includes('redakteur')) {
                    response.data.data.role = 'redakteur'
                } else {
                    response.data.data.role = 'user'
                }


                commit('SET_USER', response.data.data)
            } else {
                router.push({name: 'login'});
            }
        }
    }
};


export default {
    state,
    mutations,
    actions
}
