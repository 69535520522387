<template>

  <div class="flex flex-col w-full h-screen fixed left-0 top-0 bottom-0 right-0 bg-white " style="z-index:60;height:calc(100% - 2.5rem)" v-if="currentImage !== null">
    <div class="bg-wuh-secondary text-white text-center py-4">
      <div class="flex">
        <div class="flex w-1/3">
<!--          <button class="btn-default ml-5" @click="cropImage()">Speichern</button>-->
        </div>
        <div class="flex w-1/3 justify-center items-center">
          <h1 class="text-wuh-green">{{ decodeURIComponent(currentImage.title) }}</h1>
        </div>
        <div class="flex w-1/3 justify-end items-center ">
          <img src="@/assets/icons/close.svg" class="w-10 h-10 flex" @click="$emit('close')" />
        </div>
      </div>
    </div>

    <div class="flex-grow flex justify-center items-center">
      <div class="flex w-[18rem] h-full bg-wuh-secondary">

        <div class="flex flex-col pl-2 pr-2">
          <div class="flex flex-row mb-5">
            <span class="text-wuh-green text-xl">Bearbeiten</span>
          </div>

          <div class="flex flex-col">

            <div class="flex flex-row mb-5">
              <a @click="rotateLeft()" class="flex flex-inline items-center">
                <img src="@/assets/icons/rotate_anticlockwise.svg" class="w-10 h-10 mr-3" />
                <span class="flex w-auto text-xs">90° gegen Uhrzeigersinn drehen</span>
              </a>
            </div>
            <div class="flex flex-row mb-5">
                <a @click="rotateRight()" class="flex flex-inline items-center">
                  <img src="@/assets/icons/rotate_clockwise.svg" class="w-10 h-10 mr-3" />
                  <span class="flex w-auto text-xs">90° im Uhrzeigersinn drehen</span>
                </a>
            </div>
            <div class="flex flex-row mb-5">
              <button @click="flip(false, true)" class="flex flex-inline items-center">
                <img src="@/assets/icons/mirror.svg" class="w-10 h-10 mr-3" />
                <span class="flex w-auto text-xs">Horizontal spiegeln</span>
              </button>
            </div>
            <div class="flex flex-row mb-5">
              <button @click="flip(true, false)" class="flex flex-inline items-center">
                <img src="@/assets/icons/mirror.svg" class="w-10 h-10 mr-3" />
                <span class="flex w-auto text-xs">Vertikal spiegeln</span>
              </button>
            </div>
            <div class="flex flex-col mb-5">
              <button @click.prevent="downloadImage()" class="flex flex-row  items-center">
                <img src="@/assets/icons/download.svg" class="w-10 h-10 mr-3 " />
                <span class="flex flex-col w-auto text-xs text-left">Vorschau herunterladen<br />
                <small class="flex flex-row w-full text-xs">Bild wird nicht gespeichert!</small>
                </span>
              </button>

            </div>


            <div class="flex flex-col ml-5 mr-5  mb-4">
              <label class="flex w-full flex-col metainputlabel">Bildverhältnis ändern</label>
              <select v-model="selectedOption" @change="handleRatio" class="metainputfield flex flex-col w-full" style="appearance: auto">
                <option v-for="(option, index) in ratios" :value="index" :key="option.text">
                  {{option.text}}
                </option>
              </select>

              <template v-if="!fixedRatio">
                <span>Verhältnis manuell</span>
                <div class="flex-row mb-4">
                  <div class="flex flex-col inline-flex w-2/5">
                    <input type="text" v-model="rw" class="inline-flex text-center"/>
                  </div>
                  <div class="flex flex-col inline-flex w-1/5 text-center content-center justify-center items-center">
                    <span class="flex items-center self-center">x</span>
                  </div>
                  <div class="flex flex-col inline-flex w-2/5">
                    <input type="text" v-model="rh" class="inline-flex text-center"/>
                  </div>
                </div>
              </template>

            </div>






            <div class="flex flex-col mt-5 items-center">
              <button  v-if="!$store.state.auth.token" class="btn-default !pl-10 !pr-10" @click="downloadImage()"  v-tippy="{ content: 'Bildausschnitt herunterladen.' }" title="Bildausschnitt herunterladen">Download</button>
              <button  v-if="$store.state.auth.token" class="btn-default !pl-10 !pr-10" @click="cropImage()"  v-tippy="{ content: 'Bild wird als neues Bild gespeichert.' }" title="Als neues Bild speichern">Speichern</button>
            </div>
            <div class="flex flex-col mt-5 items-center">
              <button class="btn-default !bg-wuh-lightgreen !pl-10 !pr-10" @click="$emit('close')"  >Abbrechen</button>
            </div>

        </div>


      </div>

      </div>
      <div class="dbox flex flex-grow h-full justify-center items-center overflow-hidden z-60" ref="croppercontainer">
        <div class="cropper-container w-full h-full"
             :style="{ width: containerWidth + 'px', height: containerHeight + 'px' }">
          <ImageCropper
              class="cropperframe"
              :src="currentImage.orgimgSrc"
              :stencil-props="stencilProps"
              :canvas="canvasProps"
              @change="change"
              ref="cropper"
          ></ImageCropper>
<!--
              :default-size="defaultSize"   -->
        </div>
      </div>
    </div>
  </div>
  <div>
<!--    <img  :src="image" />-->





  </div>
</template>

<script>

import 'vue-advanced-cropper/dist/theme.compact.css';
import axios from "axios";
import {toast} from "vue3-toastify";
// import emitter from "@/addons/event-bus";

export default {
  components: {},

  props: {
    currentImage: {
      type:Object,
      required: true
    }
  },
  data() {
    return {
      stencilProps: {
        aspectRatio: 16/9
      },
      canvasProps: {
        minHeight:1000,
        minWidth:1000,
        maxHeight: 2048,
        maxWidth:2048
      },
      selectedOption: 0,
      fixedRatio: true,
      rw: 0,
      rh: 0,
      ratios: [
        {text: '16:9', value: 16/9, fixed: true},
        {text: '10:12', value: 10/12, fixed: true},
        {text: '4:3', value: 4/3, fixed: true},
        {text: '1:1', value: 1/1, fixed: true},
        {text: 'Manuell', value: 800/600, fixed: false},
        {text: 'Flexibel', value: 'flex', fixed: false},
        // {text: 'Original', value: 'org', fixed: true},
      ],
      containerWidth:0,
      containerHeight:0,
      image: null,
      imageSize: {
        width: 0,
        height: 0
      }

    }
  },
  mounted() {
    this.updateDimensions();
    if(this.currentImage.orgimgSrc == undefined) {
      this.fetchsingleimage(this.currentImage)
    }
    console.locallog('Mounted EDITOR', this.currentImage)
    window.addEventListener('resize', this.updateDimensions);

  },
  created: function() {
    this.$store.state.singleimage.editoropen = true
    //
    // console.locallog('Send Emitter imageditor_open')
    // emitter.emit('imageditor_open', true)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    this.$store.state.singleimage.editoropen = false
  },
  methods: {
    handleRatio() {
      console.locallog('stencilRatio', this.stencilProps.aspectRatio)
      let selection = this.ratios[this.selectedOption];

      // if(selection.value == 'org' || selection.value == 'flex') {
      if(selection.value == 'org') {
        this.stencilProps.aspectRatio = false
        this.fixedRatio = false
      } else if(selection.value == 'flex') {
        this.stencilProps.aspectRatio = selection.value
        this.fixedRatio = true
      } else {
        this.stencilProps.aspectRatio = selection.value
        this.fixedRatio = selection.fixed
      }

      this.$refs.cropper.refresh();
    },
    updateDimensions() {
      this.containerWidth = this.$refs.croppercontainer.clientWidth;
      this.containerHeight = this.$refs.croppercontainer.clientHeight;
      this.canvasProps.minWidth = this.containerWidth
      this.canvasProps.minHeight = this.containerHeight

      console.locallog('Calculated with and height', this.containerWidth, this.containerHeight)

    },
    async fetchsingleimage(item) {
      console.locallog('Fetch Current Image')
      if(item.orgimgSrc == undefined) {
        await axios.get(process.env.VUE_APP_APP_APIURL + `/api/getfile/${item.fileid}`, {
          responseType: 'blob', // Important
        })
        .then(response => {
          const blob = new Blob([response.data])
          item.orgimgSrc = URL.createObjectURL(blob);
        })
        .catch(error => {
          console.error(error);
        })
      }
    },

    change({coordinates, canvas}) {
      this.imageSize.width = coordinates.width
      this.imageSize.height = coordinates.height

      console.locallog('Change',coordinates, canvas)
    },
    setSize(size) {
      this.stencilProps.aspectRatio = size;
      this.$refs.cropper.refresh();
    },
    rotateLeft() {
      this.$refs.cropper.rotate(-90);
    },
    rotateRight() {
      this.$refs.cropper.rotate(90);
    },
    flip(x,y) {
      this.$refs.cropper.flip(x,y);
    },
    async cropImage() {
      const result = this.$refs.cropper.getResult();
      result.canvas.toBlob((blob) => {
        const formData = new FormData();
        formData.append("file", blob);
        formData.append("filename", this.currentImage.filename);
        formData.append("path", '/');

        axios.post(process.env.VUE_APP_APP_APIURL + '/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          console.locallog(response);
          toast.success('Erfolgreich gespeichert')
        }).catch(error => {
          console.error(error);
          toast.errback('Fehler bei der Speicherung.')
        });
      }, 'image/jpeg', 90);
    },
    downloadImage() {

      const result = this.$refs.cropper.getResult();
      result.canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a')
        link.href = url
        link.download = 'cropped_' + this.$store.state.singleimage.photo.filename // name of the downloaded file

        // append the link to the document and programmatically click it
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
  },

  watch: {
    currentImage: function(e) {
      console.locallog('Get Editor image changed', e, this.currentImage, typeof this.currentImage)
      if(this.currentImage != null) {
        this.fetchsingleimage(this.currentImage)
      }
    },
    rw: function() {
      this.stencilProps.aspectRatio = this.rw/this.rh
      this.$refs.cropper.refresh();
    },
    rh: function() {
      this.stencilProps.aspectRatio = this.rw/this.rh
      this.$refs.cropper.refresh();
    },
  }

}
</script>

<style lang="scss" scoped>

select.metainputfield {
  @apply flex-1 bg-wuh-lightgreen text-sm text-wuh-primaryfontcolor rounded mr-3 px-4;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid transparent;
  border-left: none;
  outline: none;
  margin: 4px 0 0;
  padding: 0 7px;
  max-width: 100%;
  flex-grow: 1;
  z-index: 1;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 26px;
}

</style>
