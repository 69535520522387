<template>
  <div class="flex flex-col w-full h-screen fixed left-0 top-0 bottom-0 right-[150px] bg-white z-[60]"
       style="height:calc(100% - 2.5rem);"
       :style="containerwidth"
       v-if="currentImage !== null">
    <div class="bg-wuh-secondary text-white text-center py-4">
      <div class="flex">
        <div class="flex w-1/3"></div>
        <div class="flex w-1/3 justify-center items-center text-wuh-green"><h1>{{ decodeURIComponent(currentImage.title) }}</h1></div>
        <div class="flex w-1/3 justify-end items-center ">

          <DropdownBox item="currentImage" :boxPos="'posleft'">
            <template #button>
              <img src="@/assets/icons/moreoptions.svg" class="w-10 h-10 flex"  />
            </template>
            <a href="#" class="text-gray-700 block px-4 py-2 text-sm flex flex-inline items-center" role="menuitem"
               @click.prevent="showSidebar = !showSidebar">
              <img class="w-10 h-10 mr-5" src="@/assets/icons/listview.svg" />
              <span v-if="!showSidebar">Seitenleiste öffnen</span>
              <span v-if="showSidebar">Seitenleiste schliessen</span>
            </a>
            <a href="#" class="text-gray-700 block px-4 py-2 text-sm flex flex-inline items-center" role="menuitem"
               @click.prevent="download">
              <img class="w-10 h-10 mr-5" src="@/assets/icons/download.svg" /> Herunterladen
            </a>
<!--            <a href="#" class="text-gray-700 block px-4 py-2 text-sm flex flex-inline items-center" role="menuitem"-->
<!--               v-if="$store.state.currentuser.user.role == 'admin'"-->
<!--               @click.prevent="moveImage">-->
<!--              <img class="w-10 h-10 mr-5" src="@/assets/icons/move.svg" /> Verschieben-->
<!--            </a>-->
            <a href="#" class="text-gray-700 block px-4 py-2 text-sm flex flex-inline items-center" role="menuitem"
               v-if="$store.state.currentuser.user.role == 'admin'"
               @click.prevent="removeImage">
              <img class="w-10 h-10 mr-5" src="@/assets/icons/delete.svg" /> Löschen
            </a>

          </DropdownBox>

          <img src="@/assets/icons/close.svg" class="w-10 h-10 flex" @click="$emit('close')" />
        </div>
      </div>
    </div>

    <div class="flex-grow flex justify-center items-center">
      <div class="flex w-48">
        <img src="@/assets/icons/image-left.svg" alt="Left arrow" class="w-12 h-12 md:w-56 md:h-56" @click="$emit('prev')" :class="{'opacity-10':!prevposible}"/>
      </div>
      <div class="dbox flex flex-grow h-full justify-center items-center overflow-hidden">
        <div class="w-full h-full"
             :style="{ backgroundImage: `url('${currentImage.orgimgSrc}')`, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }"
             v-if="currentImage.orgimgSrc">
<!--          <ImageEditor :currentImage="currentImage" />-->
        </div>

      </div>
      <div class="flex w-48">
        <img src="@/assets/icons/image-right.svg" alt="Right arrow" class="w-12 h-12 md:w-56 md:h-56" @click="$emit('next')"  :class="{'opacity-10':!nextposible}"/>
      </div>
    </div>
  </div>



</template>

<script>
import axios from "axios";
import DropdownBox from "@/components/DropdownBox.vue";
import {mapActions} from "vuex";
// import ImageEditor from "@/components/ImageEditor.vue";

export default {
  components: {DropdownBox},
  props: {
    images: {
      type: Array,
      required: true
    },
    currentImage: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      prevposible: false,
      nextposible: false,
      showSidebar: false
    }
  },
  mounted() {
    this.$store.state.singleimage.photo = this.currentImage
    this.showSidebar = false
    console.locallog('Mounted Image', this.$store.state.singleimage.photo)
  },
  computed: {
    containerwidth() {
      console.locallog( 'computed container with photo', this.$store.state.singleimage.photo, this.currentImage)

      if(this.$store.state.singleimage.photo  && this.showSidebar) {
        return {width: `calc(100% - 25vw)`}
      }
      return {width: `calc(100% - 0rem)`}
    }
  },
  methods: {
    ...mapActions('files', ['filesmoveImage', 'filesremoveImage', 'filesmoveFolder']),
    closeSlideShow() {
      this.$emit('close')
    },
    async fetchsingleimage(item) {
      if(item.orgimgSrc == undefined) {

        await axios.get(process.env.VUE_APP_APP_APIURL + `/api/getfile/${item.fileid}`, {
          responseType: 'blob', // Important
        })
        .then(response => {
          item.orgimgSrc = URL.createObjectURL(new Blob([response.data]));
        })
        .catch(error => {
          console.error(error);
        })
      }
    },
    async download() {

      console.locallog('$store.state.auth.token', this.$store.state.auth.token)
      if(!this.$store.state.auth.token) {
        let link = document.createElement('a');
        link.href = this.$store.state.singleimage.photo.orgimgSrc
        link.download = 'image.jpg';  // Optionaler Dateiname für das Bild
        link.click();
        return;
      }

      let fileId = []
      fileId = [this.currentImage.fileid]
      axios.post(process.env.VUE_APP_APP_APIURL + '/api/files/downloadbyid',
          {
            fileids: fileId
          },
          {
            responseType: 'blob',
          }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let contentType = response.headers['content-type'];
        let contentDisposition = response.headers['content-disposition'];
        let fileExtension = contentType.split('/')[1];
        let fileName = `${fileId}.${fileExtension}`;

        if (contentDisposition) {
          let startPos = contentDisposition.indexOf("\"") + 1;
          let endPos = contentDisposition.lastIndexOf("\"");
          fileName = contentDisposition.substring(startPos, endPos);
        }

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      });
    },
    moveImage() {
      console.locallog('currentimte', this.currentImage)
      this.filesmoveImage(this.currentImage)
    },
    removeImage() {
      let selectedimage = this.currentImage
      console.locallog('currentimte', this.currentImage)
      if( this.filesremoveImage(this.currentImage) ) {

        console.locallog('Index', this.$store.state.photos, selectedimage)

        // let index = this.$store.state.photos.findIndex((item) =>  item.fileid === selectedimage.fileid )
        // this.$store.state.photos.splice(index, 1)

        this.$store.state.singleimage.photo = null
        this.$emit('close')
      }
    },
    moveFolder() {
      console.locallog('currentimte', this.currentImage)
      this.filesmoveFolder(this.currentImage)
    },
  b64toBlob(b64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
  },
  watch: {
    '$store.state.singleimage.editoropen': function() {
      console.locallog('Emitter Editor Change')
      this.$emit('close')
    },
    currentImage: function(e) {
      let _this = this
      console.locallog('Get Slideshow image changed', e, this.currentImage, typeof this.currentImage)
      if(this.currentImage != null) {
        this.fetchsingleimage(this.currentImage)
        this.$store.state.singleimage.photo = this.currentImage
        let index = this.images.findIndex((img) => img.fileid === _this.currentImage.fileid)
        this.activeindex = index
        this.prevposible = index > 0
        this.nextposible = index < this.images.length-1

      }
    }
  }

}
</script>

<style lang="scss" >

.dotslink {
  //@apply text-[72px] tracking-[-0.4em] leading-[5px] p-0 m-0 w-[15px] text-wuh-green;
  @apply text-[72px] tracking-[-0.4em] leading-[5px] p-0 m-0 w-[15px] flex items-center justify-center text-wuh-green;
  align-self: center; /* Additional alignment help */
  line-height: 1; /* A line-height of 1 or 0 can sometimes help with vertical alignment */
}
</style>
